import React, { useState } from 'react';
import ComponentLabel from '../codegen/ComponentLabel.js';
import CrudComponent from '../common/CrudComponent';
import './ListEmailTemplates.css';

const ListEmailTemplates = () => {
  const [emailTemplates, setEmailTemplates] = useState([
    { id: 1, name: 'Welkomstmail', subject: 'Welkom bij onze service', body: 'Bedankt voor uw aanmelding.' },
    { id: 2, name: 'Herinneringsmail', subject: 'Herinnering: Invul uw enquête', body: 'Wilt u de enquête invullen?' },
  ]);

  const handleSave = (updatedRecord, index) => {
    const updatedTemplates = [...emailTemplates];
    updatedTemplates[index] = updatedRecord;
    setEmailTemplates(updatedTemplates);
  };

  const handleAdd = (newRecord) => {
    setEmailTemplates([...emailTemplates, { ...newRecord, id: emailTemplates.length + 1 }]);
  };

  const fieldConfigs = {
    name: { type: 'text' },
    subject: { type: 'text' },
    body: { type: 'textarea' },
  };

  const headers = {
    name: 'Template Naam',
    subject: 'Onderwerp',
    body: 'Inhoud',
  };

  return (
    <div>
      <ComponentLabel label='configuration/ListEmailtemplates' />
      <CrudComponent
        data={emailTemplates}
        visibleFields={['name', 'subject', 'body']}
        editableFields={['name', 'subject', 'body']}
        headers={headers}
        fieldConfigs={fieldConfigs}
        onSave={handleSave}
        onAdd={handleAdd}
      />
    </div>
  );
};

export default ListEmailTemplates;
