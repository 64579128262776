import React, { useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './Tab2CopyQuestionGroups.css';

const Tab2CopyQuestionGroups = () => {
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedSurvey, setSelectedSurvey] = useState('');

  const handleCopy = () => {
    // Logic to copy the question groups
    console.log(`Copying question groups from ${selectedSurvey} for ${selectedCompany}`);
  };

  return (
    <div className='survey-tab2-copy-question-groups'>
      <ComponentLabel label='survey/tab2/Tab2CopyQuestionGroups' />
      <select value={selectedCompany} onChange={(e) => setSelectedCompany(e.target.value)}>
        <option value=''>Select Company</option>
        <option value='Company A'>Company A</option>
        <option value='Company B'>Company B</option>
      </select>
      <select value={selectedSurvey} onChange={(e) => setSelectedSurvey(e.target.value)}>
        <option value=''>Select Survey</option>
        <option value='Survey 1'>Survey 1</option>
        <option value='Survey 2'>Survey 2</option>
      </select>
      <button onClick={handleCopy}>Copy</button>
    </div>
  );
};

export default Tab2CopyQuestionGroups;