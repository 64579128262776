import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './CloseSurvey.css';

const CloseSurvey = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState('');

  const handleClose = () => {
    navigate('/home');
  };

  return (
    <div className='survey-close-survey'>
      <ComponentLabel label='survey/tab_close_survey/CloseSurvey' style={{left:'0px'}}/>
      <h2 className='survey-close-survey-title'>Sluit onderzoek</h2>
      <input 
        className='survey-close-survey-date-input' 
        type='date' 
        value={date} 
        onChange={(e) => setDate(e.target.value)} 
      />
      <div className='survey-close-survey-button-container'>
        <button className='survey-close-survey-button'>Sluit vragenlijst op deze datum</button>
        <button className='survey-close-survey-button'>Heropen de vragenlijst</button>
      </div>
      <div className='survey-close-survey-button-newline-container'>
        <button className='survey-close-survey-button'>Maak toegankelijk in mijnrie</button>
      </div>
    </div>
  );
};

export default CloseSurvey;