import React, { useState } from 'react';
import ComponentLabel from '../codegen/ComponentLabel.js';
import './CreateSurvey.css';
import { useNavigate } from 'react-router-dom';

const CreateSurvey = () => {
  const [selectedCompany, setSelectedCompany] = useState('');
  const companies = ['Company A', 'Company B', 'Company C']; // Dummy data
  const navigate = useNavigate();

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleCreateSurvey = () => {
    // Logic to create survey
    console.log('Survey created for:', selectedCompany);
    navigate('/admin');
  };

  return (
    <div className='survey-create-survey'>
      <ComponentLabel label='survey/CreateSurvey' />
      <h2>Maak nieuw onderzoek</h2>
      <div className='survey-create-survey__plus'>+</div>
      <select value={selectedCompany} onChange={handleCompanyChange} className='survey-create-survey__select'>
        <option value=''>Selecteer bedrijf</option>
        {companies.map((company, index) => (
          <option key={index} value={company}>{company}</option>
        ))}
      </select>
      <button onClick={handleCreateSurvey} className='survey-create-survey__button'>Maak nieuw onderzoek</button>
    </div>
  );
};

export default CreateSurvey;