import React, { useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './Tab5MergeForm.css';

const Tab5MergeForm = () => {
  const [mergeName, setMergeName] = useState('');
  const [selectedColor, setSelectedColor] = useState('#000000'); // Default to black
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const departments = ['HR', 'Finance', 'IT', 'Marketing']; // Dummy data

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedDepartments([...selectedDepartments, value]);
    } else {
      setSelectedDepartments(selectedDepartments.filter(department => department !== value));
    }
  };

  const handleMerge = () => {
    console.log('Merging with name:', mergeName, 'color:', selectedColor, 'and departments:', selectedDepartments);
    // Add merge logic here
  };

  return (
    <div className='survey-tab5-merge-form'>
      <ComponentLabel label='survey/tab5/Tab5MergeForm' style={{left:'300px'}} />
      
      <div className='survey-tab5-merge-form-name'>
        <input
          type='text'
          placeholder='Naam samenvoeging'
          value={mergeName}
          onChange={(e) => setMergeName(e.target.value)}
        />
        <input
          type='color'
          value={selectedColor}
          onChange={(e) => setSelectedColor(e.target.value)}
          style={{ marginLeft: '10px' }} // Adding some space between the name and color picker
        />
      </div>

      <div className='survey-tab5-merge-form-departments'>
        {departments.map(department => (
          <label key={department}>
            <input
              type='checkbox'
              value={department}
              checked={selectedDepartments.includes(department)}
              onChange={handleCheckboxChange}
            />
            {department}
          </label>
        ))}
      </div>
      
      <button onClick={handleMerge}>Voeg samen</button>
    </div>
  );
};

export default Tab5MergeForm;
