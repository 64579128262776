// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-tab4-add-respondents {
  margin: 20px;
}

.survey-tab4-add-respondents table {
  width: 100%;
  border-collapse: collapse;
}

.survey-tab4-add-respondents th, .survey-tab4-add-respondents td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.survey-tab4-add-respondents textarea {
  width: 100%;
  resize: none;
}

.survey-tab4-add-respondents input {
  width: 50px;
  margin-top: 5px;
}

.survey-tab4-add-respondents-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.survey-tab4-add-respondents-button:hover {
  background-color: #45a049;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab4/Tab4AddRespondents.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".survey-tab4-add-respondents {\n  margin: 20px;\n}\n\n.survey-tab4-add-respondents table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.survey-tab4-add-respondents th, .survey-tab4-add-respondents td {\n  border: 1px solid #ccc;\n  padding: 10px;\n  text-align: left;\n}\n\n.survey-tab4-add-respondents textarea {\n  width: 100%;\n  resize: none;\n}\n\n.survey-tab4-add-respondents input {\n  width: 50px;\n  margin-top: 5px;\n}\n\n.survey-tab4-add-respondents-button {\n  background-color: #4CAF50;\n  color: white;\n  padding: 10px 20px;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 16px;\n  transition: background-color 0.3s;\n}\n\n.survey-tab4-add-respondents-button:hover {\n  background-color: #45a049;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
