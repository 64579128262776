// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  width: 300px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  height: 300px;
}

.login-container h2 {
  text-align: center;
}

.login-container .error {
  color: red;
  text-align: center;
}

.login-container form div {
  margin-bottom: 15px;
}

.login-container label {
  display: block;
  margin-bottom: 5px;
}

.login-container input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.login-container button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-container button:hover {
  background-color: #45a049;
}
`, "",{"version":3,"sources":["webpack://./src/components/login/LoginPage.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".login-container {\r\n  width: 300px;\r\n  margin: 100px auto;\r\n  padding: 20px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 5px;\r\n  background-color: #f9f9f9;\r\n  height: 300px;\r\n}\r\n\r\n.login-container h2 {\r\n  text-align: center;\r\n}\r\n\r\n.login-container .error {\r\n  color: red;\r\n  text-align: center;\r\n}\r\n\r\n.login-container form div {\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.login-container label {\r\n  display: block;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.login-container input {\r\n  width: 100%;\r\n  padding: 8px;\r\n  box-sizing: border-box;\r\n}\r\n\r\n.login-container button {\r\n  width: 100%;\r\n  padding: 10px;\r\n  background-color: #4CAF50;\r\n  color: white;\r\n  border: none;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n}\r\n\r\n.login-container button:hover {\r\n  background-color: #45a049;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
