// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-tab2tabs {
  padding: 20px;
}

.survey-tab2tabs__tabs {
  display: flex;
  margin-bottom: 10px;
}

.survey-tab2tabs__tab {
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
}

.survey-tab2tabs__tab.active {
  background-color: #007bff;
  color: white;
}

.survey-tab2tabs__new-tab {
  color: red;
  font-weight: bold;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.survey-tab2tabs__form {
  margin-top: 20px;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab2/Tab2Tabs.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,6BAA6B;EAC7B,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".survey-tab2tabs {\n  padding: 20px;\n}\n\n.survey-tab2tabs__tabs {\n  display: flex;\n  margin-bottom: 10px;\n}\n\n.survey-tab2tabs__tab {\n  margin-right: 10px;\n  padding: 10px;\n  cursor: pointer;\n  background-color: #f0f0f0;\n  border: none;\n  border-radius: 5px;\n}\n\n.survey-tab2tabs__tab.active {\n  background-color: #007bff;\n  color: white;\n}\n\n.survey-tab2tabs__new-tab {\n  color: red;\n  font-weight: bold;\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n}\n\n.survey-tab2tabs__form {\n  margin-top: 20px;\n  font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
