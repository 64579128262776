import React, { useState } from 'react';
import ComponentLabel from '../codegen/ComponentLabel.js';
import './CrudComponent.css';

const CrudComponent = ({ 
  data = [], 
  visibleFields, 
  editableFields, 
  headers = {}, 
  fieldConfigs = {}, 
  onSave,
  onAdd 
}) => {
  const [editIndex, setEditIndex] = useState(null);
  const [formData, setFormData] = useState({});
  const [isNew, setIsNew] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const handleEditClick = (index) => {
    setEditIndex(index);
    setIsNew(false);
    setFormData(data[index]);
  };

  const handleAddNewClick = () => {
    setEditIndex(data.length); // this can be used to uniquely identify the new record
    setIsNew(true);
    setFormData({});
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSaveClick = () => {
    if (isNew) {
      onAdd(formData);
    } else {
      onSave(formData, editIndex);
    }
    setEditIndex(null);
    setIsNew(false);
    setFormData({});
  };

  const handleSortClick = (field) => {
    const newDirection = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(newDirection);
  };

  const renderSortIcon = (field) => {
    if (sortField !== field) return null;
    return sortDirection === 'asc' ? '▲' : '▼';
  };

  const filteredData = data
    .filter((item) =>
      visibleFields.some((field) => 
        item[field].toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    )
    .sort((a, b) => {
      if (!sortField) return 0;
      if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

  const renderField = (field, value) => {
    const config = fieldConfigs[field] || { type: 'text' };
    switch (config.type) {
      case 'select':
        return (
          <select name={field} value={value || ''} onChange={handleChange}>
            {config.options && config.options.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        );
      case 'radio':
        return config.options && config.options.map(option => (
          <label key={option}>
            <input
              type="radio"
              name={field}
              value={option}
              checked={value === option}
              onChange={handleChange}
            />
            {option}
          </label>
        ));
      case 'checkbox':
        return (
          <input
            type="checkbox"
            name={field}
            checked={value || false}
            onChange={handleChange}
          />
        );
      case 'textarea':
        return (
          <textarea
            name={field}
            value={value || ''}
            onChange={handleChange}
          />
        );
      default:
        return (
          <input
            type="text"
            name={field}
            value={value || ''}
            onChange={handleChange}
          />
        );
    }
  };

  return (
    <div className='common-crud-component' style={{marginTop: '50px'}}>
      <ComponentLabel label='common/CrudComponent' style={{left: '300px'}} />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <button onClick={handleAddNewClick}>Nieuw</button>
        <input
          type="text"
          placeholder="Zoek..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      {isNew && (
        <div className='common-crud-component-form'>
          <h3>Add New Record</h3>
          {editableFields.map((field) => (
            <div key={field}>
              <label>{headers[field] || field}:</label>
              {renderField(field, formData[field])}
            </div>
          ))}
          <button onClick={handleSaveClick}>Save</button>
          <button onClick={() => setIsNew(false)}>Cancel</button>
        </div>
      )}
      <table>
        <thead>
          <tr>
            {visibleFields.map((field) => (
              <th key={field} onClick={() => handleSortClick(field)}>
                {headers[field] || field} {renderSortIcon(field)}
              </th>
            ))}
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.length > 0 ? (
            filteredData.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  {visibleFields.map((field) => (
                    <td key={field}>{item[field]}</td>
                  ))}
                  <td>
                    <button onClick={() => handleEditClick(index)}>Edit</button>
                  </td>
                </tr>
                {editIndex === index && !isNew && (
                  <tr>
                    <td colSpan={visibleFields.length + 1}>
                      <div className='common-crud-component-form'>
                        <h3>Edit Record</h3>
                        {editableFields.map((field) => (
                          <div key={field}>
                            <label>{headers[field] || field}:</label>
                            {renderField(field, formData[field])}
                          </div>
                        ))}
                        <button onClick={handleSaveClick}>Save</button>
                        <button onClick={() => setEditIndex(null)}>Cancel</button>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan={visibleFields.length + 1}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CrudComponent;
