import React, { useState } from 'react';
import ComponentLabel from '../codegen/ComponentLabel.js';
import CrudComponent from '../common/CrudComponent';
import './ListProjects.css';

const ListProjects = () => {
  const [projects, setProjects] = useState([
    { id: 1, name: 'Project Klanttevredenheid', status: 'Actief', manager: 'John Doe' },
    { id: 2, name: 'Project Productkwaliteit', status: 'Voltooid', manager: 'Jane Smith' },
  ]);

  const handleSave = (updatedRecord, index) => {
    const updatedProjects = [...projects];
    updatedProjects[index] = updatedRecord;
    setProjects(updatedProjects);
  };

  const handleAdd = (newRecord) => {
    setProjects([...projects, { ...newRecord, id: projects.length + 1 }]);
  };

  const fieldConfigs = {
    name: { type: 'text' },
    status: { type: 'select', options: ['Actief', 'Voltooid', 'Gepland'] },
    manager: { type: 'text' },
  };

  const headers = {
    name: 'Projectnaam',
    status: 'Status',
    manager: 'Projectmanager',
  };

  return (
    <div>
      <ComponentLabel label='configuration/ListProjects' />
      <CrudComponent
        data={projects}
        visibleFields={['name', 'status', 'manager']}
        editableFields={['name', 'status', 'manager']}
        headers={headers}
        fieldConfigs={fieldConfigs}
        onSave={handleSave}
        onAdd={handleAdd}
      />
    </div>
  );
};

export default ListProjects;
