import React from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './Tab4.css';
import Tab4Tabs from './Tab4Tabs.js'


const Tab4 = () => {
  return (
    <div className="tab4-container">
      <ComponentLabel label="survey/Tab4/Tab4" />
      <Tab4Tabs />
    </div>
  );
};

export default Tab4;