import React, { useState } from 'react';
import ComponentLabel from '../codegen/ComponentLabel.js';
import './ConfigurationMenu.css';
import ListUsers from './ListUsers';
import ListThemes from './ListThemes';
import ListSubthemes from './ListSubthemes';
import ListQuestiongroups from './ListQuestiongroups.js';
import ListQuestions from './ListQuestions.js';
import ListScales from './ListScales.js';
import ListEmailTemplates from './ListEmailTemplates.js';
import ListQuestiontags from './ListQuestiontags.js';
import ListBranches from './ListBranches.js';
import ListProjects from './ListProjects.js';
import ListCompanies from './ListCompanies.js';

const ConfigurationMenu = () => {
  const tabs = [
    'gebruikers',
    'bedrijven',
    'thema\'s',
    'subthema\'s',
    'vragengroepen',
    'vragen',
    'schalen',
    'email templates',
    'vragen tags',
    'branches',
    'projecten'
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const tabContent = {
    'gebruikers': <ListUsers />,
    'bedrijven' : <ListCompanies />,
    'thema\'s': <ListThemes />,
    'subthema\'s': <ListSubthemes />,
    'vragengroepen': <ListQuestiongroups />,
    'vragen': <ListQuestions />,
    'schalen': <ListScales />,
    'email templates': <ListEmailTemplates />,
    'vragen tags': <ListQuestiontags />,
    'branches': <ListBranches />,
    'projecten': <ListProjects />,
  };

  return (
    <div className='configuration-menu'>
      <ComponentLabel label='configuration/ConfigurationMenu' />
      <div className='configuration-menu-tabs'>
        {tabs.map((tab, index) => (
          <div 
            key={index} 
            className={`configuration-menu-tab ${activeTab === tab ? 'active' : ''}`} 
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </div>
        ))}
      </div>
      <div className='configuration-menu-content'>
        {tabContent[activeTab]}
      </div>
    </div>
  );
};

export default ConfigurationMenu;