// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-company-configuration {
  padding: 20px;
}

.survey-company-configuration h2 {
  color: #333;
}

.survey-company-configuration p {
  margin: 5px 0;
}

.survey-company-configuration-tabs {
  display: flex;
  margin-bottom: 20px;
}

.survey-company-configuration-tab {
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #e9e9e9;
  cursor: pointer;
  margin-right: 5px;
}

.survey-company-configuration-tab.active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  border-radius: 7px;
}

.survey-company-configuration-content {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab_company/CompanyConfiguration.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;AACxB","sourcesContent":[".survey-company-configuration {\n  padding: 20px;\n}\n\n.survey-company-configuration h2 {\n  color: #333;\n}\n\n.survey-company-configuration p {\n  margin: 5px 0;\n}\n\n.survey-company-configuration-tabs {\n  display: flex;\n  margin-bottom: 20px;\n}\n\n.survey-company-configuration-tab {\n  padding: 10px 15px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  background-color: #e9e9e9;\n  cursor: pointer;\n  margin-right: 5px;\n}\n\n.survey-company-configuration-tab.active {\n  background-color: #007bff;\n  color: white;\n  font-weight: bold;\n  border-radius: 7px;\n}\n\n.survey-company-configuration-content {\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  background-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
