import React, { useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './Tab7Upload.css';

const Tab7Upload = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle file upload logic here
    console.log('Bestand geupload:', file);
  };

  return (
    <div className='survey-tab7-upload'>
      <ComponentLabel label='survey/tab7/Tab7Upload' style={{left:'300px'}}/>
      <form onSubmit={handleSubmit} className='survey-tab7-upload-form'>
        <input type='file' onChange={handleFileChange} className='survey-tab7-upload-input' />
        <button type='submit' className='survey-tab7-upload-button'>Upload</button>
      </form>
      {file && <p className='survey-tab7-upload-file-name'>Geselecteerd bestand: {file.name}</p>}
    </div>
  );
};

export default Tab7Upload;