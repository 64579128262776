import React, { useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './Tab2Settings.css';

const Tab2Settings = () => {
  const [surveyName, setSurveyName] = useState('');
  const [pagoDi, setPagoDi] = useState('pago');
  const [type, setType] = useState('PWN');

  return (
    <div className='tab2-settings-tab2-settings'>
      <ComponentLabel label='survey/tab2/Tab2Settings' />
      <div className='tab2-settings-input-group'>
        <label htmlFor='surveyName'>Naam vragenlijst:</label>
        <input 
          type='text' 
          id='surveyName' 
          value={surveyName} 
          onChange={(e) => setSurveyName(e.target.value)} 
        />
      </div>
      <div className='tab2-settings-radio-group'>
        <label>Pago/Di:</label>
        <div>
          <label>
            <input 
              type='radio' 
              value='pago' 
              checked={pagoDi === 'pago'} 
              onChange={(e) => setPagoDi(e.target.value)} 
            />
            Pago
          </label>
          <label>
            <input 
              type='radio' 
              value='di' 
              checked={pagoDi === 'di'} 
              onChange={(e) => setPagoDi(e.target.value)} 
            />
            Di
          </label>
        </div>
      </div>
      <div className='tab2-settings-radio-group'>
        <label>Type:</label>
        <div>
          <label>
            <input 
              type='radio' 
              value='PWN' 
              checked={type === 'PWN'} 
              onChange={(e) => setType(e.target.value)} 
            />
            PWN
          </label>
          <label>
            <input 
              type='radio' 
              value='PLG' 
              checked={type === 'PLG'} 
              onChange={(e) => setType(e.target.value)} 
            />
            PLG
          </label>
          <label>
            <input 
              type='radio' 
              value='KWN' 
              checked={type === 'KWN'} 
              onChange={(e) => setType(e.target.value)} 
            />
            KWN
          </label>
          <label>
            <input 
              type='radio' 
              value='KLG' 
              checked={type === 'KLG'} 
              onChange={(e) => setType(e.target.value)} 
            />
            KLG
          </label>
        </div>
      </div>
    </div>
  );
};

export default Tab2Settings;