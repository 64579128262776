import React, { useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './Tab5MergeList.css';

const Tab5MergeList = () => {
  const [merges, setMerges] = useState([
    { name: 'Merge 1', afdelingen: 'Dept A', color: '#FF0000' },
    { name: 'Merge 2', afdelingen: 'Dept B', color: '#00FF00' },
    { name: 'Merge 3', afdelingen: 'Dept C', color: '#0000FF' }
  ]);

  const handleColorChange = (index, newColor) => {
    const updatedMerges = [...merges];
    updatedMerges[index].color = newColor;
    setMerges(updatedMerges);
  };

  const handleDelete = (index) => {
    const updatedMerges = merges.filter((_, i) => i !== index);
    setMerges(updatedMerges);
  };

  return (
    <div className='survey-tab5-merge-list'>
      <ComponentLabel label='survey/tab5/Tab5MergeList' />
      <table>
        <thead>
          <tr>
            <th>Naam</th>
            <th>Afdelingen</th>
            <th>Kleur</th>
            <th>Actie</th>
          </tr>
        </thead>
        <tbody>
          {merges.length > 0 ? merges.map((merge, index) => (
            <tr key={index}>
              <td>{merge.name}</td>
              <td>{merge.afdelingen}</td>
              <td>
                <input 
                  type='color' 
                  value={merge.color} 
                  onChange={(e) => handleColorChange(index, e.target.value)} 
                />
              </td>
              <td>
                <button onClick={() => handleDelete(index)}>Wis</button>
              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan='4'>Er zijn geen samenvoegingen</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Tab5MergeList;