// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-close-survey-page {
  position: relative;
  padding: 20px;
}

.survey-close-survey-page-cross {
  font-size: 44px;
  color: white;
  cursor: pointer;
  position: absolute;
  left: 1900px;
  top: -20px; /* Adjust top positioning as needed */
}

.survey-close-survey-page-cross:hover {
  color: red; /* Optional: Change color on hover for better visibility */
}
`, "",{"version":3,"sources":["webpack://./src/components/survey/tab_close_survey/CloseSurveyPage.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,UAAU,EAAE,qCAAqC;AACnD;;AAEA;EACE,UAAU,EAAE,0DAA0D;AACxE","sourcesContent":[".survey-close-survey-page {\n  position: relative;\n  padding: 20px;\n}\n\n.survey-close-survey-page-cross {\n  font-size: 44px;\n  color: white;\n  cursor: pointer;\n  position: absolute;\n  left: 1900px;\n  top: -20px; /* Adjust top positioning as needed */\n}\n\n.survey-close-survey-page-cross:hover {\n  color: red; /* Optional: Change color on hover for better visibility */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
