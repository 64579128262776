import React from 'react';
import { useNavigate } from 'react-router-dom';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './CloseSurveyPage.css';

const CloseSurveyPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/home');
  };

  return (
    <div className='survey-close-survey-page'>
      <ComponentLabel label='survey/tab_close_survey/CloseSurveyPage' style={{left:'1900px'}} />
      <div className='survey-close-survey-page-cross' onClick={handleClick}>
        ✖
      </div>
    </div>
  );
};

export default CloseSurveyPage;
