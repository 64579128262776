// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configuration-menu {
  width: 1900px;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
}

.configuration-menu-tabs {
  display: flex;
  justify-content: space-around;
}

.configuration-menu-tab {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: background-color 0.3s;
  background-color: white;
}

.configuration-menu-tab:hover {
  background-color: #f0f0f0;
}

.configuration-menu-tab.active {
  background-color: black;
  color: white;
}

.configuration-menu-content {
  margin-top: 20px;
  font-size: 16px;
  color: #333;
}`, "",{"version":3,"sources":["webpack://./src/components/configuration/ConfigurationMenu.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,iCAAiC;EACjC,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,WAAW;AACb","sourcesContent":[".configuration-menu {\n  width: 1900px;\n  position: absolute;\n  top: 100px;\n  left: 50%;\n  transform: translateX(-50%);\n}\n\n.configuration-menu-tabs {\n  display: flex;\n  justify-content: space-around;\n}\n\n.configuration-menu-tab {\n  padding: 10px 20px;\n  cursor: pointer;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  transition: background-color 0.3s;\n  background-color: white;\n}\n\n.configuration-menu-tab:hover {\n  background-color: #f0f0f0;\n}\n\n.configuration-menu-tab.active {\n  background-color: black;\n  color: white;\n}\n\n.configuration-menu-content {\n  margin-top: 20px;\n  font-size: 16px;\n  color: #333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
