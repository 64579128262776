// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-tab4-overview {
  padding: 20px;
}

.survey-tab4-overview__table {
  width: 100%;
  border-collapse: collapse;
}

.survey-tab4-overview__table th, .survey-tab4-overview__table td {
  border: 1px solid #ddd;
  padding: 8px;
  cursor: pointer;
}

.survey-tab4-overview__table th {
  background-color: #f2f2f2;
  text-align: left;
}

.survey-tab4-overview__table th:hover {
  background-color: #ddd;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab4/Tab4Overview.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".survey-tab4-overview {\n  padding: 20px;\n}\n\n.survey-tab4-overview__table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.survey-tab4-overview__table th, .survey-tab4-overview__table td {\n  border: 1px solid #ddd;\n  padding: 8px;\n  cursor: pointer;\n}\n\n.survey-tab4-overview__table th {\n  background-color: #f2f2f2;\n  text-align: left;\n}\n\n.survey-tab4-overview__table th:hover {\n  background-color: #ddd;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
