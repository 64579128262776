import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Menu.css';
import ComponentLabel from '../codegen/ComponentLabel.js';

const Menu = () => {
  const navigate = useNavigate();

  const handleConfigClick = () => {
    navigate('/configuration');
  };

  return (
      <div className='menu-config-icon' onClick={handleConfigClick}>
      <ComponentLabel label="common/Menu" style={{left:'-100px', top:'5px'}} />
      ⚙️
      </div>
  );
};

export default Menu;