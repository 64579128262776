import React from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './Tab2TimeEstimation.css';

const Tab2TimeEstimation = ({ questionCount = 5, estimatedTime = '01:30:00' }) => {
  return (
    <div className='survey-tab2-time-estimation'>
      <ComponentLabel label='survey/tab2/Tab2TimeEstimation' />
      <div className='survey-tab2-time-estimation-content'>
        <div className='survey-tab2-time-estimation-questions'>
          <strong>Aantal vragen:</strong>
          <div>{questionCount}</div>
        </div>
        <div className='survey-tab2-time-estimation-time'>
          <strong>Geschatte tijd:</strong>
          <div>{estimatedTime}</div>
        </div>
      </div>
    </div>
  );
};

export default Tab2TimeEstimation;