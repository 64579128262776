// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-survey-selector {
  width: 220px;
  padding: 10px;
  background-color: #f4f4f4;
  border-right: 1px solid #ccc;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  left:0px;
}

.survey-selector-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.survey-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.survey-item {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.survey-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.survey-title {
  font-weight: bold;
  font-size: 14px;
}

.survey-company {
  font-size: 12px;
  color: #666;
}

.survey-end-date,
.survey-respondents {
  font-size: 12px;
  color: #666;
}

.survey-researcher {
  font-size: 12px;
  color: #666;
}`, "",{"version":3,"sources":["webpack://./src/components/home/SurveySelector.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,4BAA4B;EAC5B,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,QAAQ;AACV;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,wCAAwC;EACxC,eAAe;EACf,qDAAqD;AACvD;;AAEA;EACE,sBAAsB;EACtB,yCAAyC;AAC3C;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;;EAEE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":[".home-survey-selector {\n  width: 220px;\n  padding: 10px;\n  background-color: #f4f4f4;\n  border-right: 1px solid #ccc;\n  height: 100vh;\n  overflow-y: auto;\n  position: fixed;\n  left:0px;\n}\n\n.survey-selector-header {\n  font-size: 18px;\n  font-weight: bold;\n  margin-bottom: 10px;\n  color: #333;\n}\n\n.survey-list {\n  list-style-type: none;\n  padding: 0;\n  margin: 0;\n}\n\n.survey-item {\n  background-color: #ffffff;\n  border: 1px solid #ddd;\n  border-radius: 5px;\n  padding: 15px;\n  margin-bottom: 10px;\n  text-align: center;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  cursor: pointer;\n  transition: transform 0.2s ease, box-shadow 0.2s ease;\n}\n\n.survey-item:hover {\n  transform: scale(1.05);\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);\n}\n\n.survey-title {\n  font-weight: bold;\n  font-size: 14px;\n}\n\n.survey-company {\n  font-size: 12px;\n  color: #666;\n}\n\n.survey-end-date,\n.survey-respondents {\n  font-size: 12px;\n  color: #666;\n}\n\n.survey-researcher {\n  font-size: 12px;\n  color: #666;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
