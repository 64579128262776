// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-Tab2Questiongroups {
  font-family: Arial, sans-serif;
}

.survey-Tab2Questiongroups table {
  width: 100%;
  border-collapse: collapse;
}

.survey-Tab2Questiongroups th, .survey-Tab2Questiongroups td {
  border: 1px solid #ddd;
  padding: 8px;
}

.survey-Tab2Questiongroups th {
  background-color: #f2f2f2;
}

.survey-Tab2Questiongroups .popup {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
}

.survey-Tab2Questiongroups div:hover .popup {
  display: block;
}

.active {
  color: green;
}

.inactive {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab2/Tab2Questiongroups.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".survey-Tab2Questiongroups {\n  font-family: Arial, sans-serif;\n}\n\n.survey-Tab2Questiongroups table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.survey-Tab2Questiongroups th, .survey-Tab2Questiongroups td {\n  border: 1px solid #ddd;\n  padding: 8px;\n}\n\n.survey-Tab2Questiongroups th {\n  background-color: #f2f2f2;\n}\n\n.survey-Tab2Questiongroups .popup {\n  display: none;\n  position: absolute;\n  background-color: white;\n  border: 1px solid #ccc;\n  padding: 10px;\n}\n\n.survey-Tab2Questiongroups div:hover .popup {\n  display: block;\n}\n\n.active {\n  color: green;\n}\n\n.inactive {\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
