import React, { useEffect, useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './CompanyStructure.css';
import { Treebeard, decorators as defaultDecorators } from 'react-treebeard';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

const FolderIconComponent = ({ node }) => {
  if (!node.children || node.children.length === 0) {
    return <FolderOutlinedIcon sx={{ color: '#FBC02D' }} />;
  }
  return node.toggled ? (
    <FolderOpenIcon sx={{ color: '#FBC02D' }} />
  ) : (
    <FolderIcon sx={{ color: '#FBC02D' }} />
  );
};

const customDecorators = {
  ...defaultDecorators,
  Toggle: () => <span style={{ marginRight: '5px' }} />, // Remove the triangle by rendering an empty span
  Header: ({ style, node, onClick }) => {
    return (
      <div style={{ ...style.base, display: 'flex', alignItems: 'center' }} onClick={onClick}>
        <FolderIconComponent node={node} />
        <span style={{ marginLeft: '5px' }}>{node.name}</span>
      </div>
    );
  },
};

const treeStyles = {
  tree: {
    base: {
      backgroundColor: 'white', // Set background color to white
    },
    node: {
      base: {
        color: 'black', // Set text color to black
      },
      activeLink: {
        background: '#ddd', // Set active node background color
      },
    },
  },
};

const CompanyStructure = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    // Initialize tree data
    const treeData = {
      name: 'Root node',
      toggled: true,
      children: [
        {
          name: 'Child node 1',
          toggled: true,
          children: [{ name: 'Grandchild 1' }, { name: 'Grandchild 2' }],
        },
        {
          name: 'Child node 2',
          toggled: true,
          children: [{ name: 'Grandchild 3' }],
        },
        {
          name: 'Child node 3', // No children for this node
        },
      ],
    };
    setData(treeData);
  }, []);

  const onToggle = (node, toggled) => {
    node.toggled = toggled;
    setData(Object.assign({}, data));
  };

  return (
    <div className="survey-company-structure">
      <ComponentLabel label="survey/tab_company/CompanyStructure" />
      <div className="survey-company-structure-tree">
        <Treebeard
          data={data}
          onToggle={onToggle}
          style={treeStyles} // Apply custom styles
          decorators={customDecorators} // Apply custom decorators
        />
      </div>
    </div>
  );
};

export default CompanyStructure;
