// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-CompanyQuestionTags {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
}

.tags-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

.tag-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tag-item label {
  width: 100%;
  margin-bottom: 5px; 
}

.tag-item input {
  width: 100%;
  max-width: 250px;
  box-sizing: border-box;
}

.new-tag-input {
  margin-top: 20px;
  width: 400px;
  gap: 10px;
}

.new-tag-input input {
  width: 200px;
  margin-right:20px;
}

.survey-CompanyQuestiontags-explanation {
    margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/survey/tab_company/CompanyQuestionTags.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".survey-CompanyQuestionTags {\n  width: 100%;\n  max-width: 1800px;\n  margin: 0 auto;\n}\n\n.tags-container {\n  display: grid;\n  grid-template-columns: repeat(5, 1fr);\n  gap: 20px;\n}\n\n.tag-item {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n.tag-item label {\n  width: 100%;\n  margin-bottom: 5px; \n}\n\n.tag-item input {\n  width: 100%;\n  max-width: 250px;\n  box-sizing: border-box;\n}\n\n.new-tag-input {\n  margin-top: 20px;\n  width: 400px;\n  gap: 10px;\n}\n\n.new-tag-input input {\n  width: 200px;\n  margin-right:20px;\n}\n\n.survey-CompanyQuestiontags-explanation {\n    margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
