import React, { useState } from 'react';
import ComponentLabel from '../codegen/ComponentLabel.js';
import CrudComponent from '../common/CrudComponent';
import './ListQuestions.css';

const ListQuestions = () => {
  const [questions, setQuestions] = useState([
    { id: 1, text: 'Hoe beoordeelt u onze klantenservice?', questiongroup: 'Algemene Vragen' },
    { id: 2, text: 'Hoe beoordeelt u de kwaliteit van ons product?', questiongroup: 'Productspecifieke Vragen' },
  ]);

  const handleSave = (updatedRecord, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = updatedRecord;
    setQuestions(updatedQuestions);
  };

  const handleAdd = (newRecord) => {
    setQuestions([...questions, { ...newRecord, id: questions.length + 1 }]);
  };

  const fieldConfigs = {
    text: { type: 'textarea' },
    questiongroup: { type: 'text' },
  };

  const headers = {
    text: 'Vraag',
    questiongroup: 'Vragengroep',
  };

  return (
    <div>
      <ComponentLabel label='configuration/ListQuestions' />
      <CrudComponent
        data={questions}
        visibleFields={['text', 'questiongroup']}
        editableFields={['text', 'questiongroup']}
        headers={headers}
        fieldConfigs={fieldConfigs}
        onSave={handleSave}
        onAdd={handleAdd}
      />
    </div>
  );
};

export default ListQuestions;
