// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab4-container {
  padding: 16px;
}

.tab4-content {
  font-size: 24px;
  color: #333;
}

.tab4-text {
  font-size: 16px;
  color: #666;
  margin-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab4/Tab4.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;EACX,eAAe;AACjB","sourcesContent":[".tab4-container {\n  padding: 16px;\n}\n\n.tab4-content {\n  font-size: 24px;\n  color: #333;\n}\n\n.tab4-text {\n  font-size: 16px;\n  color: #666;\n  margin-top: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
