// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configuration-list-questiontags {
  font-family: Arial, sans-serif;
  padding: 10px;
}

.configuration-list-questiontags ul {
  list-style-type: none;
  padding: 0;
}

.configuration-list-questiontags li {
  background-color: #f0f0f0;
  margin: 5px 0;
  padding: 8px;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/configuration/ListQuestiontags.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".configuration-list-questiontags {\n  font-family: Arial, sans-serif;\n  padding: 10px;\n}\n\n.configuration-list-questiontags ul {\n  list-style-type: none;\n  padding: 0;\n}\n\n.configuration-list-questiontags li {\n  background-color: #f0f0f0;\n  margin: 5px 0;\n  padding: 8px;\n  border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
