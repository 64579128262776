import React, { useState } from 'react';  // Import useState
import ComponentLabel from '../codegen/ComponentLabel.js';
import './ListUsers.css';
import CrudComponent from '../common/CrudComponent';  // Ensure this is imported if it's not in the same file

const ListUsers = () => {
  const [users, setUsers] = useState([
    { id: 1, name: 'John Doe', email: 'john@example.com', role: 'Admin' },
    { id: 2, name: 'Jane Smith', email: 'jane@example.com', role: 'User' },
    { id: 3, name: 'Alice Johnson', email: 'alice@example.com', role: 'User' },
  ]);

  const handleSave = (updatedRecord, index) => {
    const updatedUsers = [...users];
    updatedUsers[index] = updatedRecord;
    setUsers(updatedUsers);
  };

  const handleAdd = (newRecord) => {
    setUsers([...users, { ...newRecord, id: users.length + 1 }]);
  };

  const fieldConfigs = {
    name: { type: 'text' },
    email: { type: 'text' },
    role: { type: 'select', options: ['Admin', 'User'] },
  };

  const headers = {
    name: 'Volledige naam',
    email: 'E-mail',
    role: 'Rol',
  };

  return (
    <div>
      <ComponentLabel label='configuration/ListUsers' />
      
      <CrudComponent
        data={users}
        visibleFields={['name', 'email', 'role']}
        editableFields={['name', 'email', 'role']}
        headers={headers}
        fieldConfigs={fieldConfigs}
        onSave={handleSave}
        onAdd={handleAdd}
      />
    </div>
  );
};

export default ListUsers;
