import React, { useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './Tab8FolderSelection.css';

const Tab8FolderSelection = ({
  data,
  selectedItems,
  handleCheckboxChange,
  handleSubmit,
  isSubmitDisabled
}) => {
  const [selectedOption, setSelectedOption] = useState('draft');
  const [ids, setIds] = useState('');

  // Calculate the number of selected checkboxes
  const countChecked = Object.values(selectedItems.folderMerges || {}).filter(value => value === true).length;
  const isMaxReached = countChecked >= 5;

  return (
    <div className='survey-tab8-folder-selection'>
      <ComponentLabel label='survey/tab8/Tab8FolderSelection' style={{ left: '400px' }} />
      
      {/* Radio Buttons for 'draft', 'selection', 'all' */}
      <h3>Presentatie uitvoer</h3>
      <div>
        <input
          type="radio"
          id="draft"
          name="presentation_type"
          value="draft"
          checked={selectedOption === 'draft'}
          onChange={(e) => setSelectedOption(e.target.value)}
        />
        <label htmlFor="draft">Versie met ID's bij grafieken</label>
      </div>
      <div>
        <input
          type="radio"
          id="selection"
          name="presentation_type"
          value="selection"
          checked={selectedOption === 'selection'}
          onChange={(e) => setSelectedOption(e.target.value)}
        />
        <label htmlFor="selection">Versie met ingevoerde ID's</label>
      </div>
      {selectedOption === 'selection' && (
        <div>
          <textarea
            id="ids"
            name="presentation_ids"
            placeholder="Voer chart ID's in, gescheiden door komma's of enters"
            onChange={(e) => setIds(e.target.value)}
            rows={4}
            cols={50}
          ></textarea>
        </div>
      )}
      <div>
        <input
          type="radio"
          id="all"
          name="presentation_type"
          value="all"
          checked={selectedOption === 'all'}
          onChange={(e) => setSelectedOption(e.target.value)}
        />
        <label htmlFor="all">Versie met alle grafieken</label>
      </div>

      <h3>Groep</h3>
      <div>
        <input
          type="checkbox"
          id="survey"
          name="survey"
          checked={selectedItems.survey}
          onChange={() => handleCheckboxChange('survey')}
          disabled={isMaxReached && !selectedItems.survey}
        />
        <label>Onderzoek</label>
      </div>
      <div>
        <input
          type="checkbox"
          id="company"
          name="company"
          checked={selectedItems.company}
          onChange={() => handleCheckboxChange('company')}
          disabled={isMaxReached && !selectedItems.company}
        />
        <label>Bedrijf</label>
      </div>
      {data.sector && (
        <div>
          <input
            type="checkbox"
            id="sector"
            name="sector"
            checked={selectedItems.sector}
            onChange={() => handleCheckboxChange('sector')}
            disabled={isMaxReached && !selectedItems.sector}
          />
          <label>{data.sector.name}</label>
        </div>
      )}
      {data.project && (
        <div>
          <input
            type="checkbox"
            id="project"
            name="project"
            checked={selectedItems.project}
            onChange={() => handleCheckboxChange('project')}
            disabled={isMaxReached && !selectedItems.project}
          />
          <label>{data.project.name}</label>
        </div>
      )}

      <div>
        <h3>Samenvoegingen</h3>
        {data.folder_merges.map((merge) => (
          <div key={merge.id}>
            <input
              type="checkbox"
              id={`foldermerge_${merge.id}`}
              name={`foldermerge_${merge.id}`}
              checked={selectedItems.folderMerges[merge.id] || false}
              onChange={() => handleCheckboxChange('folderMerges', merge.id)}
              disabled={isMaxReached && !selectedItems.folderMerges[merge.id]}
            />
            <label>{merge.name}</label>
            <span
              style={{
                display: 'inline-block',
                width: '10px',
                height: '10px',
                backgroundColor: merge.chart_color,
                borderRadius: '50%',
                marginLeft: '8px'
              }}
            ></span>
          </div>
        ))}
      </div>

      {/* Warning message for maximum limit */}
      {isMaxReached && (
        <div className="warning-message" style={{ color: 'red', marginTop: '10px' }}>
          U kunt maximaal 5 items selecteren.
        </div>
      )}

      {/* Submit Button */}
      <div style={{ marginTop: '20px' }}>
        <button
          className="submit-button"
          onClick={() => handleSubmit(selectedOption, ids)}
          disabled={isSubmitDisabled(selectedOption, ids)}
        >
          Maak rapportage
        </button>
      </div>
    </div>
  );
};

export default Tab8FolderSelection;
