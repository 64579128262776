import React from 'react';
import './App.css';
import TabsMenu from '../survey/TabsMenu';
import Menu from '../common/Menu';
import CompanySelector from '../common/CompanySelector';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { PopupProvider } from '../codegen/PopupContext';
import Popup from '../codegen/Popup';

import ConfigurationMenu from '../configuration/ConfigurationMenu';
import HomeContainer from '../home/HomeContainer';
import LoginPage from '../login/LoginPage'; // Updated path
import PrivateRoute from '../login/PrivateRoute';

function Home() {
  return (
    <>
      <Menu />
      <HomeContainer />
    </>
  );
}

function Admin() {
  return (
    <>
      <Menu />
      <TabsMenu />
    </>
  );
}

function Configuration() {
  return (
    <h2>
      <ConfigurationMenu />
    </h2>
  );
}

function App() {
  return (
    <Router>
      <PopupProvider>
        <div className="main-container">
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route 
              path="/home" 
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/admin" 
              element={
                <PrivateRoute>
                  <Admin />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/configuration" 
              element={
                <PrivateRoute>
                  <Configuration />
                </PrivateRoute>
              } 
            />
          </Routes>
        </div>
        <Popup />
      </PopupProvider>
    </Router>
  );
}

export default App;
