import React, { useState } from 'react';
import ComponentLabel from '../codegen/ComponentLabel.js';
import Tab1 from './tab1/Tab1.js';
import Tab2 from './tab2/Tab2.js';
import Tab4 from './tab4/Tab4.js';
import Tab5 from './tab5/Tab5.js';
import Tab6 from './tab6/Tab6.js';
import Tab7 from './tab7/Tab7.js';
import Tab8 from './tab8/Tab8.js';
import CompanyConfiguration from './tab_company/CompanyConfiguration.js';
import CloseSurvey from './tab_close_survey/CloseSurvey.js';
import CloseSurveyPage from './tab_close_survey/CloseSurveyPage.js';
import './TabsMenu.css';

const TabsMenu = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'tab1': return <Tab1 />;
      case 'tab2': return <Tab2 />;
      case 'tab4': return <Tab4 />;
      case 'tab5': return <Tab5 />;
      case 'tab6': return <Tab6 />;
      case 'tab7': return <Tab7 />;
      case 'tab8': return <Tab8 />;
      case 'tab9': return <CloseSurvey />;
      case 'tab10': return <CompanyConfiguration />;
      default: return <Tab1 />;
    }
  };

  return (
    <div className="tabs-menu">
      <ComponentLabel label="survey/TabsMenu" />
      <CloseSurveyPage />
      
      <div className="tabs">
        <div className="tabs-left">
          {['tab1', 'tab2', 'tab4', 'tab5', 'tab6', 'tab7', 'tab8', 'tab9'].map(tab => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`tabs-button ${activeTab === tab ? 'active' : ''}`}
            >
              {tab === 'tab1' ? 'onderzoek' : 
               tab === 'tab2' ? 'vragenlijsten' : 
               tab === 'tab4' ? 'respondenten' : 
               tab === 'tab5' ? 'samenvoegen' : 
               tab === 'tab6' ? 'grafieken' : 
               tab === 'tab7' ? 'documenten' : 
               tab === 'tab8' ? 'Presentatie' : 
               'Sluit vragenlijst'}
            </button>
          ))}
        </div>
        <div className="tabs-research-name">ONDERZOEK VAN SJORS BIJ AARBOPLAATS</div>
        <button
          onClick={() => setActiveTab('tab10')}
          className={`tabs-button tabs-right ${activeTab === 'tab10' ? 'active' : ''}`}
        >
          ARBOPLAATS
        </button>
      </div>
      <div className="tab-content">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default TabsMenu;