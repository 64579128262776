import React, { useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './Tab2Tabs.css';

const Tab2Tabs = ({ surveys = [] }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({});

  const [dummyData, setDummyData] = useState([
    { id: 1, title: 'vragenlijst 1' },
    { id: 2, title: 'vragenlijst 2' }
  ]);

  const surveyData = surveys.length > 0 ? surveys : dummyData;

  const handleSurveyClick = async (surveyId) => {
    const fetchedData = await fetchSurveyData(surveyId);
    setFormData(fetchedData);
  };

  const fetchSurveyData = async (surveyId) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ id: surveyId, response: `Response for Survey ${surveyId}` });
      }, 1000);
    });
  };

  const handleNewSurvey = () => {
    const newSurveyId = dummyData.length + 1;
    const newSurvey = { id: newSurveyId, title: `Nieuwe Vragenlijst ${newSurveyId}` };
    setDummyData([...dummyData, newSurvey]);
    setFormData({ id: newSurveyId, response: `Response for ${newSurvey.title}` });
    setActiveTab(dummyData.length); // Set active tab to the new survey
  };

  return (
    <div className='survey-tab2tabs'>
      <ComponentLabel label='survey/tab2/Tab2Tabs' />
      <div className='survey-tab2tabs__tabs'>
        {surveyData.map((survey, index) => (
          <button
            key={survey.id}
            className={`survey-tab2tabs__tab ${activeTab === index ? 'active' : ''}`}
            onClick={() => {
              setActiveTab(index);
              handleSurveyClick(survey.id);
            }}
          >
            {survey.title}
          </button>
        ))}
        <button className='survey-tab2tabs__new-tab' onClick={handleNewSurvey}>Nieuwe vragenlijst</button>
      </div>
      <div className='survey-tab2tabs__form'>
        {formData.response && <p>{formData.response}</p>}
      </div>
    </div>
  );
};

export default Tab2Tabs;