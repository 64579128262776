// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-tab7files {
  padding: 10px;
  width: 600px;
  margin-top: 50px;
}

.survey-tab7files-list {
  list-style-type: none;
  padding: 0;
}

.survey-tab7files-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.survey-tab7files-trash {
  cursor: pointer;
  color: red;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab7/Tab7Files.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,UAAU;EACV,iBAAiB;AACnB","sourcesContent":[".survey-tab7files {\n  padding: 10px;\n  width: 600px;\n  margin-top: 50px;\n}\n\n.survey-tab7files-list {\n  list-style-type: none;\n  padding: 0;\n}\n\n.survey-tab7files-item {\n  display: flex;\n  justify-content: space-between;\n  padding: 5px 0;\n}\n\n.survey-tab7files-trash {\n  cursor: pointer;\n  color: red;\n  margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
