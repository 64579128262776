import React from 'react';
import './ActiveSurvey.css';
import ComponentLabel from '../codegen/ComponentLabel.js';
import { useNavigate } from 'react-router-dom';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const ActiveSurvey = ({ title, totalRespondents, finishedRespondents, companyName }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/admin');
  };

  const handleLabelClick = (event) => {
    event.stopPropagation(); // Prevent the event from bubbling up to the parent
  };

  // Calculate remaining questions
  const incompleteRespondents = totalRespondents - finishedRespondents;

  const chartData = {
    labels: ['Afgerond', 'Niet afgerond'],
    datasets: [
      {
        label: 'Completion Status',
        data: [finishedRespondents, incompleteRespondents],
        backgroundColor: ['#4CAF50', '#D3D3D3'], // Green for completed, Gray for incomplete
        hoverOffset: 4,
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
  };

  // Progress bar calculation (dummy dates for now)
  const startDate = new Date('2024-01-01');
  const endDate = new Date('2024-12-31');
  const currentDate = new Date(); // Current date
  const totalDuration = endDate - startDate;
  const elapsedDuration = Math.min(currentDate - startDate, totalDuration); // Ensure it doesn't exceed 100%
  const progressPercentage = (elapsedDuration / totalDuration) * 100;

  const latestAnswers = [
    { question: 'Wat vindt u van ons product?', dateTime: '2024-08-12 14:32', answer: 'Geweldig product!' },
    { question: 'Hoe tevreden bent u met onze service?', dateTime: '2024-08-12 14:35', answer: 'Zeer tevreden' },
    { question: 'Zou u onze service aan anderen aanbevelen? Zo ja, waarom?', dateTime: '2024-08-12 14:40', answer: 'Ja, omdat het betrouwbaar en efficiënt is.' },
  ];

  return (
    <div className='home-active-survey' onClick={handleClick}>
      <div onClick={handleLabelClick}>
        <ComponentLabel label='home/ActiveSurvey' />
      </div>
      <div className='home-active-survey__top'>
        <div className='home-active-survey__top-left'>
          <h2>{title}</h2>
          <p>Bedrijf: {companyName}</p> {/* Display company name */}
          <p>ANO-er: Sjors</p>
          <p>Startdatum: 1 Januari 2024</p>
          <p>Einddatum: 31 December 2024</p>
          <p>Totaal aantal antwoorden: {totalRespondents}</p>
        </div>
        <div className='home-active-survey__top-right'>
          <h3>Voltooiingsstatus</h3>
          <div className='chart-container'>
            <Pie data={chartData} options={chartOptions} />
          </div>
        </div>
      </div>
      {/* Progress Bar */}
      <div className='progress-bar'>
        <div className='progress-bar__background'>
          <div
            className='progress-bar__progress'
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
        <div className='progress-bar__labels'>
          <span>Startdatum</span>
          <span>Einddatum</span>
        </div>
      </div>
      <div className='home-active-survey__bottom'>
        <h3>Laatste Antwoorden</h3>
        <div className='answers-table'>
          <div className='answers-table__header'>
            <div className='answers-table__header-item answers-table__header-item--datetime'>Tijdstip</div>
            <div className='answers-table__header-item'>Vraag</div>
            <div className='answers-table__header-item answers-table__header-item--answer'>Antwoord</div>
          </div>
          {latestAnswers.map((answer, index) => (
            <div key={index} className='answers-table__row'>
              <div className='answers-table__item answers-table__item--datetime'>{answer.dateTime}</div>
              <div className='answers-table__item answers-table__item--question' title={answer.question}>
                {answer.question}
              </div>
              <div className='answers-table__item answers-table__item--answer' title={answer.answer}>
                {answer.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActiveSurvey;
