// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configuration-list-companies {
  font-family: Arial, sans-serif;
  padding: 10px;
}

.configuration-list-companies ul {
  list-style-type: none;
  padding: 0;
}

.configuration-list-companies li {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/components/configuration/ListCompanies.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,6BAA6B;AAC/B","sourcesContent":[".configuration-list-companies {\n  font-family: Arial, sans-serif;\n  padding: 10px;\n}\n\n.configuration-list-companies ul {\n  list-style-type: none;\n  padding: 0;\n}\n\n.configuration-list-companies li {\n  padding: 5px;\n  border-bottom: 1px solid #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
