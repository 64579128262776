import React, { useState } from 'react';
import ComponentLabel from '../codegen/ComponentLabel.js';
import CrudComponent from '../common/CrudComponent';
import './ListThemes.css';

const ListThemes = () => {
  const [themes, setThemes] = useState([
    { id: 1, name: 'Klanttevredenheid', description: 'Gerelateerd aan klanttevredenheid' },
    { id: 2, name: 'Productkwaliteit', description: 'Gerelateerd aan de kwaliteit van producten' },
  ]);

  const handleSave = (updatedRecord, index) => {
    const updatedThemes = [...themes];
    updatedThemes[index] = updatedRecord;
    setThemes(updatedThemes);
  };

  const handleAdd = (newRecord) => {
    setThemes([...themes, { ...newRecord, id: themes.length + 1 }]);
  };

  const fieldConfigs = {
    name: { type: 'text' },
    description: { type: 'textarea' },
  };

  const headers = {
    name: 'Thema Naam',
    description: 'Beschrijving',
  };

  return (
    <div>
      <ComponentLabel label='configuration/ListThemes' />
      <CrudComponent
        data={themes}
        visibleFields={['name', 'description']}
        editableFields={['name', 'description']}
        headers={headers}
        fieldConfigs={fieldConfigs}
        onSave={handleSave}
        onAdd={handleAdd}
      />
    </div>
  );
};

export default ListThemes;
