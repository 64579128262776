import React, { useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './CompanyInformation.css';

const CompanyInformation = () => {
  const [companyName, setCompanyName] = useState('');
  const [industry, setIndustry] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const industries = ['Technology', 'Healthcare', 'Finance', 'Education', 'Retail'];

  return (
    <div className='survey-company-information'>
      <ComponentLabel label='survey/tab_company/CompanyInformation' />
      <form>
        <div>
          <label htmlFor='companyName'>Bedrijfsnaam:</label>
          <input
            type='text'
            id='companyName'
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor='industry'>Branche:</label>
          <select
            id='industry'
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
          >
            <option value=''>Selecteer een branche</option>
            {industries.map((ind, index) => (
              <option key={index} value={ind}>{ind}</option>
            ))}
          </select>
        </div>
        <div>
          <label>
            <input
              type='checkbox'
              checked={isVisible}
              onChange={(e) => setIsVisible(e.target.checked)}
            />
            Zichtbaar frontend
          </label>
        </div>
      </form>
    </div>
  );
};

export default CompanyInformation;