/**
* The `PopupContext` component provides a centralized context and provider for managing the state and behavior of a modal popup in a React application, allowing components to easily control the modal's visibility and associated data. It can be used by wrapping components with `PopupProvider` and accessing modal state and functions through the `usePopup` custom hook.
*/
import React, { createContext, useState, useContext } from 'react';

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [label, setLabel] = useState('');
  const [inputText, setInputText] = useState('');
  const [jsCode, setJsCode] = useState('');
  const [cssCode, setCssCode] = useState('');

  const openModal = (label) => {
    setLabel(label);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <PopupContext.Provider value={{ isModalOpen, label, inputText, jsCode, cssCode, setInputText, setJsCode, setCssCode, openModal, closeModal }}>
      {children}
    </PopupContext.Provider>
  );
};

export const usePopup = () => useContext(PopupContext);
