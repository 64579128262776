// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-company-structure {
  color: black;
  display: flex;
  background-color: white;
}

.survey-company-structure-tree {
  margin-right: 20px;
  width: 200px;
  border: 1px solid #ccc;
  padding: 10px;
  height: 400px; /* Adjust height as needed */
  overflow-y: auto;
}

.survey-company-structure h2 {
  font-size: 24px;
}

.survey-company-structure p {
  font-size: 16px;
  margin-top: 10px;
}

.survey-company-structure .tree-node {
  display: flex;
  align-items: center;
}

.survey-company-structure .tree-node .folder-icon {
  margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/survey/tab_company/CompanyStructure.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,aAAa,EAAE,4BAA4B;EAC3C,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".survey-company-structure {\n  color: black;\n  display: flex;\n  background-color: white;\n}\n\n.survey-company-structure-tree {\n  margin-right: 20px;\n  width: 200px;\n  border: 1px solid #ccc;\n  padding: 10px;\n  height: 400px; /* Adjust height as needed */\n  overflow-y: auto;\n}\n\n.survey-company-structure h2 {\n  font-size: 24px;\n}\n\n.survey-company-structure p {\n  font-size: 16px;\n  margin-top: 10px;\n}\n\n.survey-company-structure .tree-node {\n  display: flex;\n  align-items: center;\n}\n\n.survey-company-structure .tree-node .folder-icon {\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
