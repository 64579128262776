/**
* The `Popup` component is a draggable modal that displays different content based on the selected tab (Update, Add, Codegen, Checkpoints) and allows users to switch between them while also selecting a GPT version. It can be used to provide a flexible interface for managing components or functionalities in a React application.
*/
import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { usePopup } from './PopupContext';
import './Popup.css';
import CodegenAssistant from './CodegenAssistant';
import CheckpointManager from './CheckpointManager';
import UpdateComponentOption from './UpdateComponentOption';
import AddComponentOption from './AddComponentOption';

const Popup = () => {
  const { isModalOpen, label, closeModal } = usePopup();
  const [activeTab, setActiveTab] = useState(0);
  const [gptVersion, setGptVersion] = useState('gpt-4o');

  const handleGptVersionChange = (event) => setGptVersion(event.target.value);

  if (!isModalOpen) return null;

  return (
    <>
      <div className="popup-overlay" onClick={closeModal}></div>
      <Draggable handle=".drag-handle">
        <div className="popup-container">
          <div className="drag-handle"></div>
          <div className="close-button" onClick={closeModal}>✖</div>
          <div className="popup-header">
            <label className="popup-label">{label}</label>
            <div className="popup_tabs">
              <a href="#" onClick={() => setActiveTab(0)} className={activeTab === 0 ? 'active' : ''}>Update</a>
              <a href="#" onClick={() => setActiveTab(1)} className={activeTab === 1 ? 'active' : ''}>Add</a>
              <a href="#" onClick={() => setActiveTab(2)} className={activeTab === 2 ? 'active' : ''}>Codegen</a>
              <a href="#" onClick={() => setActiveTab(4)} className={activeTab === 4 ? 'active' : ''}>Checkpoints</a>
            </div>
            <div className="gpt-version-switch">
              <select value={gptVersion} onChange={handleGptVersionChange}>
                <option value="gpt-4o">gpt-4o</option>
                <option value="gpt-4o-mini">gpt-4o-mini</option>
                <option value="gpt-o1">gpt-o1</option>
              </select>
            </div>
          </div>

          {activeTab === 2 && <CodegenAssistant gptVersion={gptVersion} label={label} />}
          {activeTab === 4 && <CheckpointManager />}
          {activeTab === 1 && <AddComponentOption label={label} closeModal={closeModal} />}
          {activeTab === 0 && <UpdateComponentOption label={label} />}
        </div>
      </Draggable>
    </>
  );
};

export default Popup;
