/**
* The `CodegenAssistant` component is a React interface that allows users to submit code generation prompts and track their processing status, displaying interaction steps and task statuses in real-time. It can be used in applications where users need to generate server and client-side code based on their input, with feedback on the progress of the generation tasks.
*/
import React, { useState, useEffect, useRef } from 'react';
import config from '../../config';
import './CodegenAssistant.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ComponentLabel from '../codegen/ComponentLabel.js';




function CodegenAssistant({ gptVersion, label }) {
  const [inputVisible, setInputVisible] = useState(true);
  const [userInput, setUserInput] = useState('');
  const [interactionSteps, setInteractionSteps] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [statusData, setStatusData] = useState(null);
  const [error, setError] = useState(null);
  const pollingIntervalId = useRef(null);
  const [promptId, setPromptId] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (userInput.trim() === '') return;
    startInteraction(userInput);
  };

  const startInteraction = async (input) => {
    setIsProcessing(true);
    setStatusData(null);
    try {
      const promptResponse = await sendPromptToBackend(input);
      if (promptResponse && promptResponse.id) {
        setPromptId(promptResponse.id);
        startPollingStatus(promptResponse.id);
      }
    } catch (error) {
      addInteractionStep('Error Occurred', { message: error.message });
      setIsProcessing(false);
    }
  };

  const sendPromptToBackend = async (input) => {
    const response = await fetch(`${config.apiUrlCodegen}/api/add_prompt/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_input: input }),
    });
    if (!response.ok) {
      throw new Error('Failed to send prompt to backend.');
    }
    return response.json();
  };

  const addInteractionStep = (description, data) => {
    setInteractionSteps((prev) => [
      ...prev,
      { description, data: JSON.stringify(data, null, 2) },
    ]);
  };

  const fetchStatusData = async (promptId) => {
    try {
      const response = await fetch(`${config.apiUrlCodegen}/api/prompt_status/${promptId}/`, { method: 'GET', headers: { 'Content-Type': 'application/json' } });
      if (!response.ok) {
        throw new Error('Failed to fetch status data.');
      }
      const data = await response.json();
      setStatusData(data);
      if (data.finished) {
        clearInterval(pollingIntervalId.current);
        pollingIntervalId.current = null;
        setIsProcessing(false);
        setError(null);
      }
    } catch (err) {
      setError('Waiting to be processed...');
      clearInterval(pollingIntervalId.current);
      pollingIntervalId.current = null;
      setIsProcessing(false);
    }
  };

  const startPollingStatus = (promptId) => {
    if (pollingIntervalId.current) {
      clearInterval(pollingIntervalId.current);
    }
    pollingIntervalId.current = setInterval(() => {
      fetchStatusData(promptId);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (pollingIntervalId.current) {
        clearInterval(pollingIntervalId.current);
      }
    };
  }, []);

  return (
    <div className="codegen-container">
      <ComponentLabel label="codegen/CodegenAssistant" />
      {inputVisible && (
        <form className="input-section" onSubmit={handleSubmit}>
          <textarea
            className="user-input"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="This will create server and client side code..."
            rows={4}
            cols={50}
          />
          <br />
          <button className="submit-button" type="submit">
            Submit
          </button>
        </form>
      )}
  
      {!inputVisible && (
        <div className="interaction-section">
          <h3>Interaction Steps</h3>
          <ol className="interaction-list">
            {interactionSteps.map((step, index) => (
              <li key={index} className="interaction-item">
                <strong>{step.description}</strong>
                <pre>{step.data}</pre>
              </li>
            ))}
          </ol>
        </div>
      )}
  
      {statusData && (
        <div className="status-section">
          <h3>Prompt Status</h3>
          <p>Prompt ID: {statusData.prompt_id}</p>
          <h4>Tasks</h4>
          <ul>
          {statusData.task_statuses.map((task, index) => (
            <li key={index}>
              {task.description}
              {task.status == 'done' && (
                <CheckCircleIcon className="icon-small-adjusted" style={{ color: 'green' }} />
              )}
              {task.status == 'failed' && (
                <CancelIcon className="icon-small-adjusted" style={{ color: 'red' }} />
              )}
              {task.status == 'pending' && (
                <HourglassEmptyIcon className="icon-small-adjusted" style={{ color: 'orange' }} />
              )}
              {task.status == 'in_progress' && (
                <AutorenewIcon className="icon-small-adjusted rotating-progress-icon" style={{ color: 'black' }} />
              )}
            </li>
          ))}
          </ul>
        </div>
      )}
  
      {error && <p className="error-text">Error: {error}</p>}
    </div>
  );
}

export default CodegenAssistant;