import React, { useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './Tab4AddRespondents.css';

const Tab4AddRespondents = () => {
  const [data, setData] = useState([
    { folder: 'Folder 1', questionnaires: [{ name: 'Questionnaire 1', emails: '', codes: '' }, { name: 'Questionnaire 2', emails: '', codes: '' }] },
    { folder: 'Folder 2', questionnaires: [{ name: 'Questionnaire 1', emails: '', codes: '' }, { name: 'Questionnaire 2', emails: '', codes: '' }] }
  ]);

  const handleEmailChange = (folderIndex, questionnaireIndex, value) => {
    const newData = [...data];
    newData[folderIndex].questionnaires[questionnaireIndex].emails = value;
    setData(newData);
  };

  const handleCodeChange = (folderIndex, questionnaireIndex, value) => {
    const newData = [...data];
    newData[folderIndex].questionnaires[questionnaireIndex].codes = value;
    setData(newData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can process the data here, such as sending it to a backend or simply logging it
    console.log('Submitted data:', data);
    // Example: Make an API call to submit the data
    // fetch('/api/submit', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(data),
    // })
    // .then(response => response.json())
    // .then(result => {
    //   console.log('Success:', result);
    // })
    // .catch(error => {
    //   console.error('Error:', error);
    // });
  };

  return (
    <form className='survey-tab4-add-respondents' onSubmit={handleSubmit}>
      <ComponentLabel label='survey/Tab4/Tab4AddRespondents' />
      <table>
        <thead>
          <tr>
            <th>Folder</th>
            {data[0].questionnaires.map((_, index) => (
              <th key={index}>Questionnaire {index + 1}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((folder, folderIndex) => (
            <tr key={folderIndex}>
              <td>{folder.folder}</td>
              {folder.questionnaires.map((questionnaire, questionnaireIndex) => (
                <td key={questionnaireIndex}>
                  <textarea
                    rows='2'
                    cols='35'
                    value={questionnaire.emails} // Bind value to state
                    onChange={(e) => handleEmailChange(folderIndex, questionnaireIndex, e.target.value)}
                    placeholder='Enter emails'
                  />
                  #codes <input
                    type='number'
                    value={questionnaire.codes} // Bind value to state
                    onChange={(e) => handleCodeChange(folderIndex, questionnaireIndex, e.target.value)}
                    placeholder=''
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <button type="submit">Submit</button>
    </form>
  );
};

export default Tab4AddRespondents;
