import React, { useState } from 'react';
import ComponentLabel from '../codegen/ComponentLabel.js';
import CrudComponent from '../common/CrudComponent';
import './ListSubthemes.css';

const ListSubthemes = () => {
  const [subthemes, setSubthemes] = useState([
    { id: 1, name: 'Klantenservice', parentTheme: 'Klanttevredenheid' },
    { id: 2, name: 'Productkwaliteit - Duurzaamheid', parentTheme: 'Productkwaliteit' },
  ]);

  const handleSave = (updatedRecord, index) => {
    const updatedSubthemes = [...subthemes];
    updatedSubthemes[index] = updatedRecord;
    setSubthemes(updatedSubthemes);
  };

  const handleAdd = (newRecord) => {
    setSubthemes([...subthemes, { ...newRecord, id: subthemes.length + 1 }]);
  };

  const fieldConfigs = {
    name: { type: 'text' },
    parentTheme: { type: 'text' },
  };

  const headers = {
    name: 'Subthema Naam',
    parentTheme: 'Hoofdthema',
  };

  return (
    <div>
      <ComponentLabel label='configuration/ListSubthemes' />
      <CrudComponent
        data={subthemes}
        visibleFields={['name', 'parentTheme']}
        editableFields={['name', 'parentTheme']}
        headers={headers}
        fieldConfigs={fieldConfigs}
        onSave={handleSave}
        onAdd={handleAdd}
      />
    </div>
  );
};

export default ListSubthemes;
