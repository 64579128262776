import React, { useState } from 'react';
import ComponentLabel from '../codegen/ComponentLabel.js';
import CrudComponent from '../common/CrudComponent';
import './ListScales.css';

const ListScales = () => {
  const [scales, setScales] = useState([
    { id: 1, name: 'Likert schaal', description: 'Schaal van 1 tot 5', type: 'Likert' },
    { id: 2, name: 'Sterrenwaardering', description: 'Schaal van 1 tot 5 sterren', type: 'Stars' },
  ]);

  const handleSave = (updatedRecord, index) => {
    const updatedScales = [...scales];
    updatedScales[index] = updatedRecord;
    setScales(updatedScales);
  };

  const handleAdd = (newRecord) => {
    setScales([...scales, { ...newRecord, id: scales.length + 1 }]);
  };

  const fieldConfigs = {
    name: { type: 'text' },
    description: { type: 'textarea' },
    type: { type: 'select', options: ['Likert', 'Stars', 'Numeric'] },
  };

  const headers = {
    name: 'Schaal Naam',
    description: 'Beschrijving',
    type: 'Type',
  };

  return (
    <div>
      <ComponentLabel label='configuration/ListScales' />
      <CrudComponent
        data={scales}
        visibleFields={['name', 'description', 'type']}
        editableFields={['name', 'description', 'type']}
        headers={headers}
        fieldConfigs={fieldConfigs}
        onSave={handleSave}
        onAdd={handleAdd}
      />
    </div>
  );
};

export default ListScales;
