// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-company-information {
  padding: 20px;
}

.survey-company-information label {
  display: block;
  margin-bottom: 5px;
}

.survey-company-information input,
.survey-company-information select {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.survey-company-information input[type='checkbox'] {
  width: auto;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab_company/CompanyInformation.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".survey-company-information {\n  padding: 20px;\n}\n\n.survey-company-information label {\n  display: block;\n  margin-bottom: 5px;\n}\n\n.survey-company-information input,\n.survey-company-information select {\n  width: 100%;\n  padding: 8px;\n  margin-bottom: 15px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\n.survey-company-information input[type='checkbox'] {\n  width: auto;\n  margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
