import React, { useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './Tab2Questiongroups.css';


// Dummy data
const dummyData = [
  {
    theme: 'Theme 1',
    subthemes: [
      {
        name: 'Subtheme 1-1',
        questionGroups: [
          { id: 1, name: 'QuestionGroup 1-1-1', questions: [{ text: 'Question 1', status: { PWN: false, PLG: true, KWN: false, KLG: true } }, { text: 'Question 2', status: { PWN: true, PLG: false, KWN: true, KLG: false } }] },
          { id: 2, name: 'QuestionGroup 1-1-2', questions: [{ text: 'Question 3', status: { PWN: true, PLG: true, KWN: false, KLG: false } }] }
        ]
      },
      {
        name: 'Subtheme 1-2',
        questionGroups: [
          { id: 3, name: 'QuestionGroup 1-2-1', questions: [{ text: 'Question 4', status: { PWN: false, PLG: true, KWN: false, KLG: true } }] }
        ]
      }
    ]
  },
  {
    theme: 'Theme 2',
    subthemes: [
      {
        name: 'Subtheme 2-1',
        questionGroups: [
          { id: 4, name: 'QuestionGroup 2-1-1', questions: [{ text: 'Question 5', status: { PWN: true, PLG: false, KWN: true, KLG: true } }] }
        ]
      }
    ]
  }
];

const Tab2Questiongroups = () => {
  const [selectedThemes, setSelectedThemes] = useState([]);
  const [selectedSubthemes, setSelectedSubthemes] = useState([]);
  const [selectedQuestionGroups, setSelectedQuestionGroups] = useState([]);
  const [hoveredQuestionGroup, setHoveredQuestionGroup] = useState(null);

  const handleThemeClick = (themeIndex) => {
    setSelectedThemes(prev => {
      const isSelected = prev.includes(themeIndex);
      if (isSelected) {
        return prev.filter(index => index !== themeIndex);
      } else {
        return [...prev, themeIndex];
      }
    });
  };

  const handleSubthemeClick = (themeIndex, subthemeIndex) => {
    setSelectedSubthemes(prev => {
      const key = `${themeIndex}-${subthemeIndex}`;
      const isSelected = prev.includes(key);
      if (isSelected) {
        return prev.filter(subtheme => subtheme !== key);
      } else {
        return [...prev, key];
      }
    });

    const subtheme = dummyData[themeIndex].subthemes[subthemeIndex];
    const qgIds = subtheme.questionGroups.map(qg => qg.id);

    setSelectedQuestionGroups(prev => {
      const isSelected = prev.length === qgIds.length && qgIds.every(id => prev.includes(id));
      if (isSelected) {
        return prev.filter(id => !qgIds.includes(id));
      } else {
        return [...prev, ...qgIds];
      }
    });
  };

  const handleQuestionGroupClick = (qgId) => {
    setSelectedQuestionGroups(prev => {
      const isSelected = prev.includes(qgId);
      if (isSelected) {
        return prev.filter(id => id !== qgId);
      } else {
        return [...prev, qgId];
      }
    });
  };

  const handleMouseEnter = (qg) => {
    setHoveredQuestionGroup(qg);
  };

  const handleMouseLeave = () => {
    setHoveredQuestionGroup(null);
  };

  return (
    <div className="tab2-questiongroups">
      <ComponentLabel label='survey/tab2/Tab2Questiongroups' />
      <div className="tab2-questiongroups__table">
        {dummyData.map((theme, themeIndex) => (
          <div key={themeIndex} className="tab2-questiongroups__column">
            <div
              className={`tab2-questiongroups__header ${selectedThemes.includes(themeIndex) ? 'selected' : ''}`}
              onClick={() => handleThemeClick(themeIndex)}
            >
              {theme.theme}
            </div>
            {theme.subthemes.map((subtheme, subthemeIndex) => (
              <div
                key={subthemeIndex}
                className={`tab2-questiongroups__subtheme ${selectedSubthemes.includes(`${themeIndex}-${subthemeIndex}`) ? 'selected' : ''}`}
                onClick={() => handleSubthemeClick(themeIndex, subthemeIndex)}
              >
                {subtheme.name}
                {subtheme.questionGroups.map((qg) => (
                  <div
                    key={qg.id}
                    className={`tab2-questiongroups__questiongroup ${selectedQuestionGroups.includes(qg.id) ? 'selected' : ''}`}
                    onClick={() => handleQuestionGroupClick(qg.id)}
                    onMouseEnter={() => handleMouseEnter(qg)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {qg.name}
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
        {hoveredQuestionGroup && (
          <div className="tab2-questiongroups__popup">
            {hoveredQuestionGroup.questions.map((question, index) => (
              <div key={index} className="tab2-questiongroups__question">
                {Object.entries(question.status).map(([status, isActive]) => (
                  <span key={status} className={`tab2-questiongroups__icon ${isActive ? 'active' : ''}`}>
                    {status}
                  </span>
                ))}
                {question.text}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Tab2Questiongroups;