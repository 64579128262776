// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-close-survey {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.survey-close-survey-title {
  margin-bottom: 15px;
  font-size: 24px;
  color: #333;
}

.survey-close-survey-date-input {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
}

.survey-close-survey-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  margin-bottom: 10px;
}

.survey-close-survey-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 5px;
}

.survey-close-survey-button:hover {
  background-color: #0056b3;
}

.survey-close-survey-button-newline-container {
  width: 100%;
  max-width: 300px;
}
`, "",{"version":3,"sources":["webpack://./src/components/survey/tab_close_survey/CloseSurvey.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,iCAAiC;EACjC,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".survey-close-survey {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n  background-color: #f9f9f9;\n  border-radius: 8px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n.survey-close-survey-title {\n  margin-bottom: 15px;\n  font-size: 24px;\n  color: #333;\n}\n\n.survey-close-survey-date-input {\n  margin-bottom: 15px;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  width: 100%;\n  max-width: 300px;\n}\n\n.survey-close-survey-button-container {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  max-width: 300px;\n  margin-bottom: 10px;\n}\n\n.survey-close-survey-button {\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  padding: 10px 15px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n  margin: 5px;\n}\n\n.survey-close-survey-button:hover {\n  background-color: #0056b3;\n}\n\n.survey-close-survey-button-newline-container {\n  width: 100%;\n  max-width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
