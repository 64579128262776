// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-create-survey {
  width: 900px;
  height: 500px;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom:100px;
  background-color:white;
  border-radius:10px;
}

.survey-create-survey__plus {
  font-size: 200px;
  color: gray;
  margin: 20px 0;
}

.survey-create-survey__select {
  margin: 20px 0;
}

.survey-create-survey__button {
  margin-top: 20px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.survey-create-survey__button:hover {
  background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/CreateSurvey.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,wCAAwC;EACxC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".survey-create-survey {\n  width: 900px;\n  height: 500px;\n  border: 1px solid #ccc;\n  padding: 20px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom:100px;\n  background-color:white;\n  border-radius:10px;\n}\n\n.survey-create-survey__plus {\n  font-size: 200px;\n  color: gray;\n  margin: 20px 0;\n}\n\n.survey-create-survey__select {\n  margin: 20px 0;\n}\n\n.survey-create-survey__button {\n  margin-top: 20px;\n  padding: 10px 15px;\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.survey-create-survey__button:hover {\n  background-color: #0056b3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
