import React from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import Tab7Upload from './Tab7Upload.js'
import Tab7Files from './Tab7Files.js'

const Tab7 = () => {
  return (
    <div>
      <ComponentLabel label="survey/Tab7/Tab7" />
        <Tab7Upload />
        <Tab7Files />
    </div>
  );
};

export default Tab7;