import React, { useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import { Bar } from 'react-chartjs-2';
import './Tab1.css';

const Tab1 = () => {
  const [onderzoekNaam, setOnderzoekNaam] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [date, setDate] = useState('');

  const data = {
    labels: ['2023-10-01', '2023-10-02', '2023-10-03'],
    datasets: [{
      label: 'Aantal Respondenten',
      data: [12, 19, 3],
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
    }],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label}: ${context.raw}`;
          },
        },
      },
    },
    layout: {
      padding: 20,
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="tab1-component">
      <ComponentLabel label="survey/tab1/Tab1" />
      <h2>Instellingen</h2>
      <input 
        className="tab1-input" 
        type="text" 
        placeholder="Naam onderzoek" 
        value={onderzoekNaam} 
        onChange={(e) => setOnderzoekNaam(e.target.value)} 
      />
      <button className="tab1-button-save">Sla op</button>
      <div className="tab1-select-container">
        <select 
          className="tab1-select"
          value={selectedProject} 
          onChange={(e) => setSelectedProject(e.target.value)}
        >
          <option value="">Selecteer project</option>
          <option value="project1">Project 1</option>
          <option value="project2">Project 2</option>
        </select>
      </div>

      <h2>Statistieken</h2>
      <div className="tab1-content-container">
        <div className="tab1-statistics">
          <p>Aantal respondenten: 34</p>
          <p>Aantal respondenten begonnen/niet afgerond: 10</p>
          <p>Aantal respondenten afgerond: 20</p>
          <p>Aantal respondenten niet gestart: 4</p>
        </div>
        <div className="tab1-chart-container">
          <Bar data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default Tab1;