import React from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './Tab8GeneratePresentation.css';

const Tab8GeneratePresentation = ({ progress, statusMessage, currentChart, currentComment }) => {
  return (
    <div>
      <ComponentLabel label='survey/tab8/Tab8GeneratePresentation' style={{ left: '400px' }} />
      <h2>{statusMessage}</h2>

      {/* Large Current Chart Display */}
      <div style={{
        width: '800px',  // Adjusted width
        height: '500px',
        backgroundColor: '#e0e0e0',
        margin: '20px auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}>
        {currentComment ? (
          <p style={{ fontSize: '32px' }}>{currentComment}</p>
        ) : currentChart ? (
          <>
            <img src={currentChart.image} alt="Current Chart" style={{ width: '100%', height: 'auto' }} />
          </>
        ) : (
          <p>Loading chart...</p>
        )}
      </div>

      {/* Progress Bar */}
      <div style={{ width: '100%', backgroundColor: '#ddd', marginTop: '20px', position: 'relative' }}>
        <div
          style={{
            width: `${progress}%`,
            height: '30px',
            backgroundColor: '#4caf50',
            transition: 'width 0.5s ease',
            position: 'relative'
          }}
        >
          <span style={{
            position: 'absolute',
            right: '10px',
            color: '#fff',
            fontWeight: 'bold',
            lineHeight: '30px'
          }}>
            {progress}%
          </span>
        </div>
      </div>
    </div>
  );
};

export default Tab8GeneratePresentation;
