// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-tab2-copy-question-groups {
  position: absolute;
  right: 20px;
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  top: 100px;
}

.survey-tab2-copy-question-groups select,
.survey-tab2-copy-question-groups button {
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.survey-tab2-copy-question-groups button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.survey-tab2-copy-question-groups button:hover {
  background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab2/Tab2CopyQuestionGroups.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,wCAAwC;EACxC,UAAU;AACZ;;AAEA;;EAEE,gBAAgB;EAChB,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".survey-tab2-copy-question-groups {\n  position: absolute;\n  right: 20px;\n  border: 1px solid #ccc;\n  padding: 20px;\n  margin: 10px;\n  border-radius: 5px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  top: 100px;\n}\n\n.survey-tab2-copy-question-groups select,\n.survey-tab2-copy-question-groups button {\n  margin-top: 10px;\n  padding: 10px;\n  width: 100%;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\n.survey-tab2-copy-question-groups button {\n  background-color: #007bff;\n  color: white;\n  cursor: pointer;\n}\n\n.survey-tab2-copy-question-groups button:hover {\n  background-color: #0056b3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
