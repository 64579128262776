


const config = {
  apiUrlCodegenClient: 'http://localhost:5004',
  apiUrlCodegenServer: 'http://localhost:5001',
  apiUrlRest: 'http://localhost:8001',
  apiUrlCodegen: 'http://localhost:9001',
  enableLabels: false,
};

export default config;
