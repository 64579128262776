/**
* The `ComponentLabel` is a React component that displays a clickable label, which opens a modal with the label's content when clicked. It dynamically adjusts its position based on its parent element's styling and can be conditionally rendered based on a configuration setting.
*/

import React, { useEffect, useRef, useState } from 'react';
import { usePopup } from './PopupContext';
import './ComponentLabel.css';
import config from '../../config';

const ComponentLabel = ({ label, style }) => {
  const labelRef = useRef(null);
  const { openModal } = usePopup();
  
  const [computedStyle, setComputedStyle] = useState({});

  useEffect(() => {
    const determinePosition = () => {
      if (labelRef.current && labelRef.current.parentNode) {
        const parent = labelRef.current.parentNode;
        const parentStyle = window.getComputedStyle(parent);

        if (parentStyle.position === 'static') {
          parent.style.position = 'relative';
        }

        setComputedStyle({}); // Clear the inline style since we're using CSS
      }
    };

    determinePosition();
    window.addEventListener('resize', determinePosition);
    return () => window.removeEventListener('resize', determinePosition);
  }, []);

  const handleLabelClick = () => {
    openModal(label);
  };

  // Conditionally render the label based on config.enableLabels
  if (!config.enableLabels) {
    return null; // Don't render anything if labels are disabled
  }

  return (
    <div
      ref={labelRef}
      className="component-label"
      onClick={handleLabelClick}
      style={{ ...computedStyle, ...style }}  // Merging computed styles and passed styles
    >
      {label}
    </div>
  );
};

export default ComponentLabel;
