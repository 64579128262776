import React, { useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './Tab4Overview.css';

const Tab4Overview = ({ respondents = [] }) => {
  const dummyData = [
    { email: 'dummy@example.com', hash: '123abc', code: 'XYZ', vragenlijst: 'Survey 1', afdeling: 'HR', status: 'Completed' },
    { email: 'dummy2@example.com', hash: '456def', code: 'ABC', vragenlijst: 'Survey 2', afdeling: 'IT', status: 'Pending' }
  ];

  const dataToDisplay = respondents.length > 0 ? respondents : dummyData;

  const [sortConfig, setSortConfig] = useState(null);

  const sortedData = React.useMemo(() => {
    let sortableItems = [...dataToDisplay];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [dataToDisplay, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className='survey-tab4-overview'>
      <ComponentLabel label='survey/Tab4/Tab4Overview' />
      <table className='survey-tab4-overview__table'>
        <thead>
          <tr>
            <th onClick={() => requestSort('email')}>Email</th>
            <th onClick={() => requestSort('hash')}>Hash</th>
            <th onClick={() => requestSort('code')}>Code</th>
            <th onClick={() => requestSort('vragenlijst')}>Vragenlijst</th>
            <th onClick={() => requestSort('afdeling')}>Afdeling</th>
            <th onClick={() => requestSort('status')}>Status</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((respondent, index) => (
            <tr key={index}>
              <td>{respondent.email}</td>
              <td>{respondent.hash}</td>
              <td>{respondent.code}</td>
              <td>{respondent.vragenlijst}</td>
              <td>{respondent.afdeling}</td>
              <td>{respondent.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Tab4Overview;