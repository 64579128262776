import React from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import Tab2Tabs from './Tab2Tabs.js';
import Tab2Settings from './Tab2Settings.js';
import Tab2TimeEstimation from './Tab2TimeEstimation.js';
import Tab2CopyQuestionGroups from './Tab2CopyQuestionGroups.js';
import Tab2Questiongroups from './Tab2Questiongroups.js';
import './Tab2.css';

const Tab2 = () => {
  return (
    <div className="tab2-component">
      <ComponentLabel label="survey/tab2/Tab2" />
      <Tab2Tabs />
      <Tab2Settings />
      <Tab2TimeEstimation />
      <Tab2CopyQuestionGroups />
      <Tab2Questiongroups />
    </div>
  );
};

export default Tab2;