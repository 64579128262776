// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-tab8-generate-presentation {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}

.survey-tab8-generate-presentation ul {
  list-style-type: none;
  padding: 0;
}

.survey-tab8-generate-presentation li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.survey-tab8-generate-presentation li:last-child {
  border-bottom: none;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab8/Tab8GeneratePresentation.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".survey-tab8-generate-presentation {\n  padding: 20px;\n  background-color: #f9f9f9;\n  border: 1px solid #ddd;\n}\n\n.survey-tab8-generate-presentation ul {\n  list-style-type: none;\n  padding: 0;\n}\n\n.survey-tab8-generate-presentation li {\n  padding: 10px;\n  border-bottom: 1px solid #ccc;\n}\n\n.survey-tab8-generate-presentation li:last-child {\n  border-bottom: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
