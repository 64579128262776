// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.component-label {
    color: white;
    background-color: black;
    padding: 2px 6px;
    cursor: pointer;
    font-size: 13px;
    z-index: 1000;
    position: absolute;
    top: -22px;
    left: 0;
    white-space: nowrap;
    border-radius: 8px;
  }`, "",{"version":3,"sources":["webpack://./src/components/codegen/ComponentLabel.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;IAChB,eAAe;IACf,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,OAAO;IACP,mBAAmB;IACnB,kBAAkB;EACpB","sourcesContent":["\r\n.component-label {\r\n    color: white;\r\n    background-color: black;\r\n    padding: 2px 6px;\r\n    cursor: pointer;\r\n    font-size: 13px;\r\n    z-index: 1000;\r\n    position: absolute;\r\n    top: -22px;\r\n    left: 0;\r\n    white-space: nowrap;\r\n    border-radius: 8px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
