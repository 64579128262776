import React, { useState } from 'react';
import ComponentLabel from '../codegen/ComponentLabel.js';
import CrudComponent from '../common/CrudComponent';
import './ListQuestiontags.css';

const ListQuestiontags = () => {
  const [questionTags, setQuestionTags] = useState([
    { id: 1, tag: 'Klantenservice', description: 'Gerelateerd aan klantenservice vragen' },
    { id: 2, tag: 'Productkwaliteit', description: 'Gerelateerd aan productkwaliteit vragen' },
  ]);

  const handleSave = (updatedRecord, index) => {
    const updatedTags = [...questionTags];
    updatedTags[index] = updatedRecord;
    setQuestionTags(updatedTags);
  };

  const handleAdd = (newRecord) => {
    setQuestionTags([...questionTags, { ...newRecord, id: questionTags.length + 1 }]);
  };

  const fieldConfigs = {
    tag: { type: 'text' },
    description: { type: 'textarea' },
  };

  const headers = {
    tag: 'Tag Naam',
    description: 'Beschrijving',
  };

  return (
    <div>
      <ComponentLabel label='configuration/ListQuestiontags' />
      <CrudComponent
        data={questionTags}
        visibleFields={['tag', 'description']}
        editableFields={['tag', 'description']}
        headers={headers}
        fieldConfigs={fieldConfigs}
        onSave={handleSave}
        onAdd={handleAdd}
      />
    </div>
  );
};

export default ListQuestiontags;
