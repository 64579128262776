import React from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import Tab5MergeForm from './Tab5MergeForm.js'
import Tab5MergeList from './Tab5MergeList.js'

const Tab5 = () => {
  return (
    <div>
      <ComponentLabel label="survey/tab5/Tab5" />
        <Tab5MergeForm />
        <Tab5MergeList />
    </div>
  );
};

export default Tab5;