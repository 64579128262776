// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab2-settings-tab2-settings {
  margin: 0px;
  margin-bottom: 100px;
  background-color: white;
}
.tab2-settings-input-group {
  margin-bottom: 15px;
}
.tab2-settings-radio-group {
  margin-bottom: 15px;
}
.tab2-settings-radio-group label {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab2/Tab2Settings.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,oBAAoB;EACpB,uBAAuB;AACzB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".tab2-settings-tab2-settings {\n  margin: 0px;\n  margin-bottom: 100px;\n  background-color: white;\n}\n.tab2-settings-input-group {\n  margin-bottom: 15px;\n}\n.tab2-settings-radio-group {\n  margin-bottom: 15px;\n}\n.tab2-settings-radio-group label {\n  margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
