import React, { useEffect, useState } from 'react';
import SurveySelector from './SurveySelector';
import ActiveSurveys from './ActiveSurveys';
import config from '../../config';

function HomeContainer() {
  const [activeSurveys, setActiveSurveys] = useState([]);
  const [finishedSurveys, setFinishedSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(config.apiUrlRest + '/api/home_data/')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();  // Automatically parses JSON
      })
      .then(data => {
        console.log('Fetched Data:', data);  // Inspect fetched data

        // Use the 'finished' property to filter surveys
        const active = data.filter(survey => !survey.finished);  // Active surveys (not finished)
        const finished = data.filter(survey => survey.finished); // Finished surveys

        console.log('Active Surveys:', active);  // Inspect active surveys
        console.log('Finished Surveys:', finished);  // Inspect finished surveys

        setActiveSurveys(active);
        setFinishedSurveys(finished);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <SurveySelector surveys={finishedSurveys} />
      <div style={{ width: 'calc(100% - 240px)', left: '240px', position: 'absolute' }}>
        <ActiveSurveys surveys={activeSurveys} />
      </div>
    </>
  );
}

export default HomeContainer;
