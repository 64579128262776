// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */
html, body, #root, .main-container {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  /*align-items: center;*/
  font-family: Arial, sans-serif;
  background-color: #043b4b;
}

.main-container {
  width: 100vw;
  height: 100vh;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/components/app/App.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,YAAY;EACZ,SAAS;EACT,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,8BAA8B;EAC9B,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;AACpB","sourcesContent":["/* src/App.css */\r\nhtml, body, #root, .main-container {\r\n  height: 100%;\r\n  margin: 0;\r\n  padding: 0;\r\n  display: flex;\r\n  justify-content: center;\r\n  /*align-items: center;*/\r\n  font-family: Arial, sans-serif;\r\n  background-color: #043b4b;\r\n}\r\n\r\n.main-container {\r\n  width: 100vw;\r\n  height: 100vh;\r\n  position: relative;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
