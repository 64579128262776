// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab8-submit-button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.tab8-submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.tab8-submit-button:not(:disabled):hover {
    background-color: #0056b3;
}

.question-chart {
    background-color: lightyellow;
}

.questiongroup-chart {
    background-color: lightblue;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab8/Tab8.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;IACf,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".tab8-submit-button {\n    background-color: #007bff;\n    border: none;\n    color: white;\n    padding: 10px 20px;\n    text-align: center;\n    text-decoration: none;\n    display: inline-block;\n    font-size: 16px;\n    margin: 4px 2px;\n    cursor: pointer;\n    border-radius: 4px;\n    transition: background-color 0.3s ease;\n}\n\n.tab8-submit-button:disabled {\n    background-color: #cccccc;\n    cursor: not-allowed;\n}\n\n.tab8-submit-button:not(:disabled):hover {\n    background-color: #0056b3;\n}\n\n.question-chart {\n    background-color: lightyellow;\n}\n\n.questiongroup-chart {\n    background-color: lightblue;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
