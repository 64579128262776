import React from 'react';
import { useNavigate } from 'react-router-dom';
import ComponentLabel from '../codegen/ComponentLabel.js';
import './SurveySelector.css';

const SurveySelector = ({ surveys = [] }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/admin');
  };

  return (
    <div className='home-survey-selector'>
      <ComponentLabel label='home/SurveySelector' style={{ top: '0px' }} />
      <h2 className='survey-selector-header'>Afgeronde onderzoeken</h2>
      <ul className='survey-list'>
        {surveys.map((survey) => (
          <li
            key={survey.id}
            className='survey-item'
            onClick={handleClick}
          >
            <div className='survey-title'>{survey.name}</div>
            <div className='survey-company'>Bedrijf: {survey.company.name}</div>
            <div className='survey-end-date'>Sluitdatum: {survey.endDate}</div>
            <div className='survey-respondents'>Respondenten: {survey.finished_respondents}/{survey.total_respondents}</div>
            <div className='survey-researcher'>Onderzoeker: {survey.researcher}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SurveySelector;
