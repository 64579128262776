// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-tab5-merge-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top:40px;
}

.survey-tab5-merge-form-departments {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.survey-tab5-merge-form input[type='text'] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.survey-tab5-merge-form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
    width: 200px;
}

.survey-tab5-merge-form button:hover {
  background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab5/Tab5MergeForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;IACb,YAAY;AAChB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".survey-tab5-merge-form {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  margin-top:40px;\n}\n\n.survey-tab5-merge-form-departments {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n\n.survey-tab5-merge-form input[type='text'] {\n  padding: 8px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\n.survey-tab5-merge-form button {\n  padding: 10px;\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n    width: 200px;\n}\n\n.survey-tab5-merge-form button:hover {\n  background-color: #0056b3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
