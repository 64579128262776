import React, { useState } from 'react';
import ComponentLabel from '../codegen/ComponentLabel.js';
import CrudComponent from '../common/CrudComponent';
import './ListQuestiongroups.css';

const ListQuestiongroups = () => {
  const [questiongroups, setQuestiongroups] = useState([
    { id: 1, name: 'Algemene Vragen', theme: 'Klanttevredenheid' },
    { id: 2, name: 'Productspecifieke Vragen', theme: 'Productkwaliteit' },
  ]);

  const handleSave = (updatedRecord, index) => {
    const updatedQuestiongroups = [...questiongroups];
    updatedQuestiongroups[index] = updatedRecord;
    setQuestiongroups(updatedQuestiongroups);
  };

  const handleAdd = (newRecord) => {
    setQuestiongroups([...questiongroups, { ...newRecord, id: questiongroups.length + 1 }]);
  };

  const fieldConfigs = {
    name: { type: 'text' },
    theme: { type: 'text' },
  };

  const headers = {
    name: 'Vragengroep Naam',
    theme: 'Thema',
  };

  return (
    <div>
      <ComponentLabel label='configuration/ListQuestiongroups' />
      <CrudComponent
        data={questiongroups}
        visibleFields={['name', 'theme']}
        editableFields={['name', 'theme']}
        headers={headers}
        fieldConfigs={fieldConfigs}
        onSave={handleSave}
        onAdd={handleAdd}
      />
    </div>
  );
};

export default ListQuestiongroups;
