/**
* The `CheckpointManager` component is designed to manage and display a list of checkpoints, allowing users to create new checkpoints and check out existing ones. It can be used in applications that require version control or state management, providing a user interface for interacting with checkpoint data fetched from an API.
*/
import React, { useEffect, useState } from 'react';
import config from '../../config';
import './CheckpointManager.css';

const CheckpointManager = () => {
    const [checkpoints, setCheckpoints] = useState([]);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchCheckpoints();
    }, []);

    const fetchCheckpoints = async () => {
        try {
            const response = await fetch(config.apiUrlCodegen + '/api_checkpoints/');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setCheckpoints(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const createCheckpoint = async () => {
        try {
            const response = await fetch(config.apiUrlCodegen + '/api_checkpoints/create/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            await fetchCheckpoints();
            setMessage("");
        } catch (err) {
            setError(err.message);
        }
    };

    const checkoutCheckpoint = async (frontendCommit, backendCommit) => {
        try {
            const response = await fetch(config.apiUrlCodegen + '/api_checkpoints/checkout/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    frontend_commit: frontendCommit,
                    backend_commit: backendCommit,
                }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            alert("Checked out successfully!");
        } catch (err) {
            setError(err.message);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <div className="input-container">
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Checkpoint message"
                    className="checkpoint-input"
                />
                <button onClick={createCheckpoint} className="checkpoint-button">
                    Create Checkpoint
                </button>
            </div>
            <table className="checkpoint-table">
                <thead>
                    <tr>
                        <th>Description</th>
                        <th className="timestamp-column">Timestamp</th>
                        <th className="action-column">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {checkpoints.map((checkpoint, index) => (
                        <tr key={index}>
                            <td>{checkpoint.message}</td>
                            <td>{checkpoint.timestamp}</td>
                            <td>
                                <button
                                    onClick={() => checkoutCheckpoint(checkpoint.frontend_commit, checkpoint.backend_commit)}
                                    className="checkout-button"
                                >
                                    Checkout
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CheckpointManager;
