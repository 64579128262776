import React, { useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import CompanyInformation from './CompanyInformation.js';
import CompanyQuestionTags from './CompanyQuestionTags.js';
import CompanyStructure from './CompanyStructure.js';
import './CompanyConfiguration.css';

const CompanyConfiguration = () => {
  const [activeTab, setActiveTab] = useState('bedrijf');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className='survey-company-configuration'>
      <ComponentLabel label='survey/tab_company/CompanyConfiguration' />
      <div className='survey-company-configuration-tabs'>
        <button className={`survey-company-configuration-tab ${activeTab === 'bedrijf' ? 'active' : ''}`} onClick={() => handleTabChange('bedrijf')}>Bedrijf</button>
        <button className={`survey-company-configuration-tab ${activeTab === 'structuur' ? 'active' : ''}`} onClick={() => handleTabChange('structuur')}>Structuur</button>
        <button className={`survey-company-configuration-tab ${activeTab === 'vragen-tags' ? 'active' : ''}`} onClick={() => handleTabChange('vragen-tags')}>Vragen Tags</button>
        <button className={`survey-company-configuration-tab ${activeTab === 'nulvragen' ? 'active' : ''}`} onClick={() => handleTabChange('nulvragen')}>Nulvragen</button>
        <button className={`survey-company-configuration-tab ${activeTab === 'automatische-reminders' ? 'active' : ''}`} onClick={() => handleTabChange('automatische-reminders')}>Automatische Reminders</button>
        <button className={`survey-company-configuration-tab ${activeTab === 'welkom-tekst' ? 'active' : ''}`} onClick={() => handleTabChange('welkom-tekst')}>Welkom Tekst</button>
        <button className={`survey-company-configuration-tab ${activeTab === 'uitnodigingsmail' ? 'active' : ''}`} onClick={() => handleTabChange('uitnodigingsmail')}>Uitnodigingsmail</button>
      </div>
      <div className='survey-company-configuration-content'>
        {activeTab === 'bedrijf' && <CompanyInformation />}
        {activeTab === 'vragen-tags' && <CompanyQuestionTags />}
        {activeTab === 'structuur' && <CompanyStructure />}
        {activeTab === 'automatische-reminders' && <p>Content for Automatische Reminders</p>}
        {activeTab === 'welkom-tekst' && <p>Content for Welkom Tekst</p>}
        {activeTab === 'uitnodigingsmail' && <p>Content for Uitnodigingsmail</p>}
        {activeTab === 'nulvragen' && <p>Content for Nulvragen</p>}
      </div>
    </div>
  );
};

export default CompanyConfiguration;