import React, { useState, useEffect } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './Tab8.css';
import config from '../../../config.js';
import Tab8FolderSelection from './Tab8FolderSelection.js';
import Tab8GeneratePresentation from './Tab8GeneratePresentation.js';
import PptxGenJS from "pptxgenjs";


// Helper function to map slot index to location
const getLocationForSlot = (index, isWide) => {
    const locations = ['linksboven', 'rechtsboven', 'linksonder', 'rechtsonder'];
  
    if (isWide) {
      // Wide charts occupy two slots; determine if they are on top or bottom
      return index < 2 ? 'boven' : 'onder';
    } else {
      return locations[index];
    }
  };

const splitTextByWidth = (text, maxCharsPerLine) => {
    let lines = [];
    let currentLine = '';

    text.split(' ').forEach(word => {
        // Als het huidige woord niet meer op de regel past, voeg de regel toe en begin een nieuwe
        if ((currentLine.length + word.length + 1) > maxCharsPerLine) {
            lines.push(currentLine.trim());
            currentLine = word + ' ';  // Begin een nieuwe regel
        } else {
            currentLine += word + ' ';  // Voeg het woord toe aan de huidige regel
        }
    });

    // Voeg de laatste regel toe als die niet leeg is
    if (currentLine.trim().length > 0) {
        lines.push(currentLine.trim());
    }

    return lines;
};

let callCount = 0;
const getServerCommentForSlide = async (chartDescriptions) => {
    /*
    if (callCount >= 7) {
        return '';
    }
    callCount ++;
    //*/

    try {
      const response = await fetch(
        `${config.apiUrlRest}/api/survey/ai_charts_comment/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ charts: chartDescriptions }),
        }
      );
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Server error: ${errorText}`);
      }
  
      const data = await response.json();
  
      if (typeof data.comment !== 'string') {
        throw new Error('Invalid response format: comment should be a string.');
      }
  
      return data.comment;
    } catch (error) {
      console.error('Error fetching comment:', error);
      return 'Kan geen commentaar genereren.';
    }
  };

  
const addButtonsAndLegend = (pptx, slide, mergeDetails, pixelToInch, lastTitleSlideIndex, themeName, subthemeName, questiongroupName) => {
    const buttonWidth = 288 * pixelToInch;
    const blackButtonHeight = 56 * pixelToInch;
    const greenButtonHeight = 80 * pixelToInch;
    const blackButtonY = 32 * pixelToInch;
    const greenButtonY = 108 * pixelToInch;
    const subthemeButtonY = 199.5 * pixelToInch;
    const questiongroupnameButtonY = 293 * pixelToInch;

    const mergeName = "Che dep./ goods receiving, Int transport, repare & insp";
    const maxCharsPerLine = 30;  // Stel een limiet voor karakters per regel
   
    const getFontSizeBasedOnStringLength = (inputString) => {
        // Controleer of de string minder dan 30 karakters heeft
        if (inputString.length < 30) {
            return 21;
        } else {
            return 15;
        }
    }
      
    
    // Voeg het plaatje toe met hoogte van 100%
    slide.addImage({
        path: '/images/powerpoint_presentation/menu_left.png',
        x: 0,
        y: 0,
        h: '100%',  // Gebruik volledige hoogte
        w: 330 * pixelToInch,
    });

    // Voeg Home-knop toe
    slide.addShape("rect", {
        x: 33 * pixelToInch,
        y: blackButtonY,
        w: buttonWidth,
        h: blackButtonHeight,
        fill: { color: '000000', transparency: 100 },
        hyperlink: { slide: 6 },
    });


    // Link naar laatste thema
    slide.addText(themeName, {
        x: 33 * pixelToInch,
        y: greenButtonY,
        w: buttonWidth,
        h: greenButtonHeight,
        fontFace: 'Inter',
        align: 'center',
        color: 'FFFFFF',
        fontSize: 21,
    });

    slide.addShape("rect", {
        x: 33 * pixelToInch,
        y: greenButtonY,
        w: buttonWidth,
        h: greenButtonHeight,
        hyperlink: { slide: lastTitleSlideIndex + 1},
        fill: { color: '000000', transparency: 100 },
    });

    // Subthema
    slide.addText(subthemeName, {
        x: 33 * pixelToInch,
        y: subthemeButtonY,
        w: buttonWidth,
        h: greenButtonHeight,
        fontFace: 'Inter',
        align: 'center',
        color: '000000',
        //fill: { color: 'FFFF00', transparency: 50 }, 
        fontSize: getFontSizeBasedOnStringLength(subthemeName),
    });

    console.log('questiongroupName: ',questiongroupName);
    if (questiongroupName) {
        // Vragengroep.
        slide.addText(questiongroupName, {
            x: 33 * pixelToInch,
            y: questiongroupnameButtonY,
            w: buttonWidth,
            h: greenButtonHeight,
            fontFace: 'Inter',
            align: 'center',
            color: '000000',
            //fill: { color: 'FFFF00', transparency: 50 }, 
            fontSize: getFontSizeBasedOnStringLength(questiongroupName),
        });    
    } else {
        slide.addShape(pptx.ShapeType.rect, {
            x: (33 * pixelToInch) - 0.02, // Verplaats 0.02 inches naar links
            y: questiongroupnameButtonY - 0.02, // Verplaats 0.02 inches omhoog
            w: buttonWidth + 0.04, // Maak 0.04 inches breder
            h: greenButtonHeight + 0.04, // Maak 0.04 inches hoger
            fill: { color: '#FBFDFF' }, // Vierkantkleur
        });
    }

    // Zet de startpositie voor de cirkels wat lager om er zeker van te zijn dat ze zichtbaar zijn
    const mergeYStart = 670 * pixelToInch;  // Verlaag deze waarde voor een betere zichtbaarheid
    const circleDiameter = 20 * pixelToInch;
    const textOffset = 30 * pixelToInch;

    // Log de keys en waarden voor debug-doeleinden
    //console.log('Used merges:', mergeDetails);

    // Voeg cirkels en namen toe
    Object.keys(mergeDetails).forEach((mergeId, index) => {
        const mergeColor = mergeDetails[mergeId].color;
        const mergeName = mergeDetails[mergeId].name;

        if (typeof mergeColor !== 'string') {
            console.error(`Invalid color for ${mergeId}: ${mergeColor}`);
            return; // Skip invalid colors
        }

        // Voeg een cirkel toe voor elk merge-item
        slide.addShape("ellipse", {
            x: 32 * pixelToInch,
            y: mergeYStart + (index * 40 * pixelToInch),  // Verticale offset voor elke cirkel
            w: circleDiameter,  // Breedte van de cirkel (in pixels)
            h: circleDiameter,  // Hoogte van de cirkel (in pixels)
            fill: { color: mergeColor },  // Kleur van de cirkel
        });

        // Splits de tekst als deze langer is dan 200 pixels
        const lines = splitTextByWidth(mergeName, maxCharsPerLine);

        // Voeg elke regel tekst toe onder elkaar
        lines.forEach((line, lineIndex) => {
            slide.addText(line, {
                x: 32 * pixelToInch + textOffset,  // Plaats de tekst rechts van de cirkel
                y: mergeYStart + (index * 40 * pixelToInch) + (circleDiameter / 4) + (lineIndex * 18 * pixelToInch),  // Verticaal uitlijnen met de cirkel en per regel verschuiven
                fontSize: 16,
                fontFace: 'Inter',
                align: 'left',
                color: '000000',  // Zwarte tekst
            });
        });
    });

};


         
  const addTitlePage = (pptx) => {
    const slide = pptx.addSlide();
    slide.background = { fill: '#FBFCFE' };
  
    slide.addText('Organisatie-rapport', {
      x: 1.5,
      y: 3,
      w: 7,
      h: 2,
      fontSize: 36,
      bold: true,
      fontFace: 'Inter',
      align: 'center',
    });
  
    slide.addText('Datum: ' + new Date().toLocaleDateString(), {
      x: 1.5,
      y: 5.5,
      w: 7,
      h: 1,
      fontFace: 'Inter',
      fontSize: 18,
      align: 'center',
    });
  };
  
  const addGeneralPositivePointsPage = (pptx) => {
    let slide = pptx.addSlide();

    slide.addImage({
        path: '/images/powerpoint_presentation/page_positive.png',
        x: 0,
        y: 0,
        h: '100%',
        w: '100%',
    });

    slide = pptx.addSlide();

    slide.addImage({
        path: '/images/powerpoint_presentation/page_positive.png',
        x: 0,
        y: 0,
        h: '100%',
        w: '100%',
    });
  };
  
  const addGeneralImprovementPointsPage = (pptx) => {
    let slide = pptx.addSlide();

    slide.addImage({
        path: '/images/powerpoint_presentation/page_improvements.png',
        x: 0,
        y: 0,
        h: '100%',
        w: '100%',
    });

    slide = pptx.addSlide();

    slide.addImage({
        path: '/images/powerpoint_presentation/page_improvements.png',
        x: 0,
        y: 0,
        h: '100%',
        w: '100%',
    });
  };
  
  const addMainMenuPage = (pptx, themes) => {
    const slide = pptx.addSlide();
      
    slide.addImage({
        path: '/images/powerpoint_presentation/main_menu.png',
        x: 0,
        y: 0,
        h: '100%',
        w: '100%',
    });
  };
  
   

const Tab8 = () => {
    const [data, setData] = useState(null);
    const [selectedItems, setSelectedItems] = useState({
        company: false,
        sector: false,
        project: false,
        folderMerges: {}
    });
    const [progress, setProgress] = useState(0);
    const [statusMessage, setStatusMessage] = useState('');
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [currentChart, setCurrentChart] = useState(null);
    const [currentComment, setCurrentComment] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [allComments, setAllComments] = useState('');

    let slideIndex = 7;
    let globalComments = '';
    let themeJudgement = {1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '',10: ''};
    let presentationType = '';
    let chartIds = [];


    const addComment = (newComment) => {
        // Controleer op lege of alleen-spatie waarden
        if (!newComment || newComment.trim() === '') {
            return;
        }
    
        setAllComments(prevComments => {
            // Voeg een spatie toe als er al eerdere comments zijn, anders gewoon het comment toevoegen
            const updatedComments = prevComments ? prevComments + ' ' + newComment : newComment;
            globalComments = updatedComments;
            return updatedComments;
        });
    };

    useEffect(() => {
        console.log('Updated comments:', allComments);
    }, [allComments]);    

    const sendCommentsForAnalysis = async (pptx) => {
        console.log('Comments before sending to server:', globalComments);


        if (!globalComments || globalComments.trim() === '') {
            console.error('Comments zijn leeg, niets om te versturen');
            return;
        }        
        /*

        Verwijder return.

       return;
        */
       try {
            console.log('Sending comments:', globalComments); 

          const response = await fetch(config.apiUrlRest + '/api/survey/ai_analyze_comments/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ comments: globalComments }),
          });
    
          const data = await response.json();
    
          if (response.ok) {
            updatePowerPointWithConclusions(pptx, data);
          } else {
            console.error('Fout bij API-aanroep:', data);
          }
        } catch (error) {
          console.error('Netwerkfout:', error);
        }
      };
      
      
    const addChartsAndText = async (
        pptx,
        initialSlide,
        groupData,
        firstChartX,
        firstChartY,
        chartWidth,
        chartHeight,
        wideChartWidth,
        margin,
        pixelToInch,
        lastTitleSlideIndex,
        mergeDetails,
        themeName,
        subthemeName,
        questionGroupname
      ) => {
        let currentSlide = initialSlide;
        currentSlide.background = { fill: '#FBFCFE' };
        let slots = [null, null, null, null];
        let unitsOnSlide = 0;
        const charts = [];
      
        // Collect charts
        Object.values(groupData.questions).forEach((questionData) => {
          if (questionData.image) {
            charts.push({
              image: questionData.image,
              isWide: questionData.isWide,
              question: questionData.name,
              scale: questionData.scale,
              results: questionData.data,
            });
          }
        });
      
        let i = 0;
        while (i < charts.length) {
          const chartData = charts[i];
          const { image, isWide } = chartData;
      
          let requiredUnits = isWide ? 2 : 1;
          let canPlaceChart = false;
      
          if (unitsOnSlide + requiredUnits <= 4) {
            if (isWide) {
              for (let row = 0; row < 2; row++) {
                if (slots[row * 2] === null && slots[row * 2 + 1] === null) {
                  slots[row * 2] = { ...chartData, slotIndex: row * 2 };
                  slots[row * 2 + 1] = { ...chartData, slotIndex: row * 2 };
                  unitsOnSlide += 2;
                  canPlaceChart = true;
                  break;
                }
              }
            } else {
              for (let index = 0; index < slots.length; index++) {
                if (slots[index] === null) {
                  slots[index] = { ...chartData, slotIndex: index };
                  unitsOnSlide += 1;
                  canPlaceChart = true;
                  break;
                }
              }
            }
          }
      
          if (!canPlaceChart) {
            // Finalize current slide
            slots.forEach((slotChart, index) => {
              if (slotChart) {
                const isSlotWide = slotChart.isWide;
                const currentRow = Math.floor(index / 2);
                const currentCol = isSlotWide ? 0 : index % 2;
      
                let xPos = isSlotWide
                  ? firstChartX
                  : firstChartX + currentCol * (chartWidth + margin);
                let yPos = firstChartY + currentRow * (chartHeight + margin);
      
                currentSlide.addImage({
                  data: slotChart.image,
                  x: xPos,
                  y: yPos,
                  w: isSlotWide ? wideChartWidth : chartWidth,
                  h: chartHeight,
                });
              }
            });
      
            // Prepare chart descriptions with merge names and locations
            const chartDescriptions = slots
              .filter((slot) => slot)
              .map((slot) => {
                // Replace merge IDs with merge names in results
                const resultsWithNames = {};
                Object.keys(slot.results).forEach((mergeId) => {
                  const mergeName = mergeDetails[mergeId]?.name || mergeId;
                  resultsWithNames[mergeName] = slot.results[mergeId];
                });
      
                // Determine the location of the chart
                const location = getLocationForSlot(slot.slotIndex, slot.isWide);
      
                return {
                  question: slot.question,
                  results: resultsWithNames,
                  scale: slot.scale,
                  location: location,
                };
              });

              // Generate a single comment for the slide
            const commentary = await getServerCommentForSlide(chartDescriptions);
      
            // Add the single commentary to the slide
            currentSlide.addText(commentary, {
              x: 352 * pixelToInch,
              y: 872 * pixelToInch,
              w: 1427 * pixelToInch,
              h: 140 * pixelToInch,
              fontSize: 18,
              align: 'left',
              color: '000000',
              fontFace: 'Inter',
            });
            setCurrentComment(commentary);
            addComment("thema: " + themeName + " - " + commentary + "\n\n");
    
            // Start a new slide
            currentSlide = pptx.addSlide();
            slideIndex++;
            currentSlide.background = { fill: '#FBFCFE' };
            slots = [null, null, null, null];
            unitsOnSlide = 0;
      
            // Add buttons and legend
            addButtonsAndLegend(
              pptx,
              currentSlide,
              mergeDetails,
              pixelToInch,
              lastTitleSlideIndex,
              themeName,
              subthemeName,
              questionGroupname
            );
      
            continue;
          }
      
          Object.assign(mergeDetails, groupData.data);
          i += 1;
        }
      
        // Finalize the last slide
        slots.forEach((slotChart, index) => {
          if (slotChart) {
            const isSlotWide = slotChart.isWide;
            const currentRow = Math.floor(index / 2);
            const currentCol = isSlotWide ? 0 : index % 2;
      
            let xPos = isSlotWide
              ? firstChartX
              : firstChartX + currentCol * (chartWidth + margin);
            let yPos = firstChartY + currentRow * (chartHeight + margin);
      
            currentSlide.addImage({
              data: slotChart.image,
              x: xPos,
              y: yPos,
              w: isSlotWide ? wideChartWidth : chartWidth,
              h: chartHeight,
            });
          }
        });
      
        // Prepare chart descriptions for the last slide
        const chartDescriptions = slots
          .filter((slot) => slot)
          .map((slot) => {
            // Replace merge IDs with merge names in results
            const resultsWithNames = {};
            Object.keys(slot.results).forEach((mergeId) => {
              const mergeName = mergeDetails[mergeId]?.name || mergeId;
              resultsWithNames[mergeName] = slot.results[mergeId];
            });
      
            // Determine the location of the chart
            const location = getLocationForSlot(slot.slotIndex, slot.isWide);
      
            return {
              question: slot.question,
              results: resultsWithNames,
              scale: slot.scale,
              location: location,
            };
          });
      
        // Generate a single comment for the last slide
        const commentary = await getServerCommentForSlide(chartDescriptions);
      
        // Add the commentary to the slide
        currentSlide.addText(commentary, {
          x: 352 * pixelToInch,
          y: 872 * pixelToInch,
          w: 1477 * pixelToInch,
          h: 140 * pixelToInch,
          fontSize: 18,
          fontFace: 'Inter',
          align: 'left',
          color: '000000',
        });
        setCurrentComment(commentary);
        addComment("thema: " + themeName + "\n" + commentary + "\n\n");
      
        return mergeDetails;
      };
      


    // Functie om de PowerPoint te updaten met de ontvangen data
    const updatePowerPointWithConclusions = (pptx, data) => {

        const positive_1 = data["positive_1"];
        const positive_2 = data["positive_2"];
        const negative_1 = data["negative_1"];
        const negative_2 = data["negative_2"];

        /*
        themeJudgement[1] = data["1"];
        themeJudgement[2] = data["2"];
        themeJudgement[3] = data["3"];
        themeJudgement[4] = data["4"];
        themeJudgement[5] = data["5"];
        themeJudgement[6] = data["6"];
        themeJudgement[7] = data["7"];
        themeJudgement[8] = data["8"];
        themeJudgement[9] = data["9"];
        themeJudgement[10] = data["10"];
        */

        let slide2 = pptx.getSlide(2);
        let slide3 = pptx.getSlide(3);
        let slide4 = pptx.getSlide(4);
        let slide5 = pptx.getSlide(5);

        // Slide 2: Voeg positieve feedback toe
        if (slide2) {
            slide2.addText(positive_1, { x: 1, y: 1.8, w: '90%', fontSize: 20, color: '000000', fontFace: 'Inter',valign: 'top' });
        }
        if (slide3) {
            slide3.addText(positive_2, { x: 1, y: 1.8, w: '90%', fontSize: 20, color: '000000', fontFace: 'Inter',valign: 'top' });
        }

        // Slide 4: Voeg negatieve feedback toe
        if (slide4) {
            slide4.addText(negative_1, { x: 1, y: 1.8, w: '90%', fontSize: 20, color: '000000', fontFace: 'Inter',valign: 'top' });
        }
        if (slide5) {
            slide5.addText(negative_2, { x: 1, y: 1.8, w: '90%', fontSize: 20, color: '000000', fontFace: 'Inter',valign: 'top' });
        }
    };      

    const pixelsToInches = (pixels) => pixels / 96;

    const addChapterLinksToslide6 = (pptx, chapters) => {
        let slide6 = pptx.getSlide(6); // Selecteer slide 6 waar de links moeten komen
    
        // Functie om pixels om te rekenen naar inches (1 inch = 96 pixels)
        
    
        console.log("chapters: ", chapters);
        
        // Configuratie voor de knoppen per themeId (hier geef je per id de kolom en rij aan)
        const positions = {
            1: { column: 1, row: 1 },
            2: { column: 1, row: 2 },
            3: { column: 1, row: 3 },
            4: { column: 1, row: 4 },
            5: { column: 2, row: 1 },
            6: { column: 1, row: 5 },
            7: { column: 2, row: 2 },
            8: { column: 2, row: 3 },
            9: { column: 2, row: 4 },
            10: { column: 2, row: 5 },
        };
    
        // Configuratie voor de knoppen en hun posities (nu in inches)
        const config = {
            xLeftCol: pixelsToInches(545),   // x-positie van de linkerkolom (omgezet naar inches)
            xRightCol: pixelsToInches(970), // x-positie van de rechterkolom (omgezet naar inches)
            yStart: pixelsToInches(230),     // Start y-positie voor de knoppen (omgezet naar inches)
            yGap: pixelsToInches(133),       // Afstand tussen de knoppen verticaal (omgezet naar inches)
            buttonWidth: pixelsToInches(400), // Breedte van de knoppen (omgezet naar inches)
            buttonHeight: pixelsToInches(120), // Hoogte van de knoppen (omgezet naar inches)
            fontSize: 16,                    // Lettergrootte van de knoptekst (geen omzetting nodig)
            activeFill: { color: '00FF00', transparency: 100 },  // Kleur voor actieve knoppen
            disabledFill: { color: 'FFFFFF', transparency: 50 }, // Kleur voor uitgeschakelde knoppen
            textColor: '000000' // Tekstkleur voor de knoppen
        };
    
        if (slide6) {
            chapters.forEach((chapter, index) => {
                const position = positions[chapter.themeId]; // Haal de kolom en rij op voor dit themeId
    
                if (!position) {
                    console.log(`Geen positie gevonden voor themeId ${chapter.themeId}`);
                    return; // Als er geen positie is, overslaan
                }
    
                // Bepaal de x- en y-positie op basis van de kolom en rij
                const isLeftColumn = position.column === 1;
                const xPosition = isLeftColumn ? config.xLeftCol : config.xRightCol;
                const yPosition = config.yStart + (position.row - 1) * config.yGap; // Bereken de y-positie op basis van de rij
    
                // Loop om te kijken of de knop actief of disabled moet zijn
                let nextChapter = chapters[index + 1];
                let isDisabled = nextChapter && (nextChapter.slide - chapter.slide === 1);

                // Log de status van de knop (aan/uit)
                console.log(`Knop voor hoofdstuk ${chapter.themeId} is ${isDisabled ? "uitgeschakeld" : "actief"}.`);
    
                let buttonStyle = {
                    x: xPosition,
                    y: yPosition,
                    w: config.buttonWidth, // Breedte van de knop
                    h: config.buttonHeight, // Hoogte van de knop
                    fontSize: config.fontSize,
                    align: 'center',
                    fontSize: 20,
                    valign: 'bottom',
                    fill: isDisabled ? config.disabledFill : config.activeFill, // Transparante knop of wit als disabled
                    color: config.textColor, // Kleur van de tekst (zwarte tekst)
                    underline: false // Zorg ervoor dat er geen onderstreping is
                };
                
                /*
                let judgementText = ' ';
                if (!isDisabled) {
                    judgementText = "" + themeJudgement[chapter.themeId];
                    
                    // Voeg de tekst toe zonder hyperlink
                    slide6.addText(judgementText, buttonStyle);
                }*/
                
                
                if (!isDisabled) {
                    // Voeg een onzichtbare rechthoek met de hyperlink toe
                    slide6.addShape(pptx.ShapeType.rect, {
                        x: xPosition,
                        y: yPosition,
                        w: config.buttonWidth, // Breedte van de rechthoek
                        h: config.buttonHeight, // Hoogte van de rechthoek
                        fill: { color: 'FFFFFF', transparency: 100 }, // Onzichtbare rechthoek
                        line: { color: 'FFFFFF', transparency: 100 }, // Geen rand zichtbaar
                        hyperlink: { slide: chapter.slide + 1} // Hyperlink naar de juiste slide
                    });
                } else {
                    // Voeg een onzichtbare rechthoek met de hyperlink toe
                    slide6.addShape(pptx.ShapeType.rect, {
                        x: xPosition,
                        y: yPosition,
                        w: config.buttonWidth, // Breedte van de rechthoek
                        h: config.buttonHeight, // Hoogte van de rechthoek
                        fill: { color: 'FFFFFF', transparency: 25 }, // Onzichtbare rechthoek
                        line: { color: 'FFFFFF', transparency: 100 }, // Geen rand zichtbaar
                    });                    
                }
            });
        }
    };
    

    const generatePowerPointWithAIComments = async (
        consolidatedData,
        themes,
        subthemes,
        questiongroups,
        mergeDetails
    ) => {
        const pptx = new PptxGenJS();
        console.log('Starting PowerPoint generation');
    
        pptx.defineLayout({ name: 'Custom', width: 20, height: 11.25 });
        pptx.layout = 'Custom';
    
        let chapters = [];
        let subthemePages = {}; // Object to store subtheme page numbers
    
        // Add the new introductory pages
        addTitlePage(pptx);
        addGeneralPositivePointsPage(pptx);
        addGeneralImprovementPointsPage(pptx);
        addMainMenuPage(pptx, themes);
    
        const pixelToInch = 1 / 96;
        const chartWidth = 756 * pixelToInch;
        const chartHeight = 388 * pixelToInch;
        const firstChartX = 352 * pixelToInch;
        const firstChartY = 32 * pixelToInch;
        const margin = 20 * pixelToInch;
        const wideChartWidth = chartWidth * 2 + margin;
    
        let processedSlides = 0; // Counter for processed slides
    
        // --- Calculate totalSlides before entering the loops ---
        let totalSlides = 0;
        for (const themeId in themes) {
            totalSlides++; // For the theme title slide
    
            const relevantSubthemes = Object.entries(subthemes).filter(
                ([subthemeId, subtheme]) => subtheme.theme_id == themeId
            );
    
            for (const [subthemeId, subtheme] of relevantSubthemes) {
                for (const qgId in consolidatedData) {
                    const groupData = consolidatedData[qgId];
    
                    if (groupData.subtheme_id == subthemeId) {
                        totalSlides++; // For each group data slide
                    }
                }
            }
        }
    
        // Initialize an object to hold all combined data per subtheme
        const combinedDataPerSubtheme = {};
    
        if (presentationType === 'selection') {
            // First, collect all charts per subtheme
            for (const qgId in consolidatedData) {
                const groupData = consolidatedData[qgId];
                if (qgId === "nulvragen") {
                    continue; // Skip 'nulvragen'
                }
    
                const subthemeId = groupData.subtheme_id;
                if (!combinedDataPerSubtheme[subthemeId]) {
                    combinedDataPerSubtheme[subthemeId] = {
                        questions: [],
                        data: {},
                        subtheme_id: subthemeId,
                    };
                }
    
                // Collect questions with images
                for (const questionId in groupData.questions) {
                    const questionData = groupData.questions[questionId];
                    if (questionData.image) {
                        // Add questionData to the questions array
                        combinedDataPerSubtheme[subthemeId].questions.push(questionData);
                    }
                }
    
                // Merge data without overwriting existing keys
                for (const dataKey in groupData.data) {
                    if (!combinedDataPerSubtheme[subthemeId].data.hasOwnProperty(dataKey)) {
                        combinedDataPerSubtheme[subthemeId].data[dataKey] = groupData.data[dataKey];
                    } else {
                        // Handle duplicate keys if necessary
                        console.warn(`Duplicate data key ${dataKey} when combining groupData.data`);
                    }
                }
            }
        }
    
        const themeSlides = {}; // Store theme slide references and subtheme counters
    
        for (const themeId in themes) {
            const themeName = themes[themeId];
    
            const relevantSubthemes = Object.entries(subthemes).filter(
                ([subthemeId, subtheme]) => subtheme.theme_id == themeId
            );
    
            // Skip adding theme slide if no subthemes have data
            if (relevantSubthemes.length === 0) {
                continue;
            }
    
            const titleSlide = pptx.addSlide();
            titleSlide.background = { fill: '#FBFCFE' };
    
            // Add the image with 100% height
            if (themeId < 13) {
                titleSlide.addImage({
                    path: '/images/powerpoint_presentation/theme_' + themeId + '.png',
                    x: 0,
                    y: 0,
                    h: '100%',
                    w: '80%',
                });
            }
    
            titleSlide.addText(themeName, {
                x: 0.5,
                y: 0.5,
                fontSize: 60,
                bold: true,
                fontFace: 'Inter',
                color: 'FFFFFF'
            });
    
            // Initialize subtheme positioning
            const xPos = '82%';
            const initialY = 1.0;
            const yIncrement = 1.0;
    
            // Add the "Subthema's:" title
            titleSlide.addText(
                "Subthema's:",
                {
                    x: xPos,
                    y: initialY - yIncrement,
                    w: 3.5,
                    h: 1,
                    fontSize: 24,
                    bold: true,
                }
            );
    
            // Initialize subtheme counter and store theme slide info
            themeSlides[themeId] = {
                slide: titleSlide,
                counter: 0,
                xPos: xPos,
                initialY: initialY,
                yIncrement: yIncrement
            };
    
            // Initialize subtheme pages for the current theme if not already done
            if (!subthemePages[themeId]) {
                subthemePages[themeId] = {};
            }
    
            // Add the title page to the chapters with the current slide index
            chapters.push({ themeId, slide: pptx.slides.indexOf(titleSlide) });
            processedSlides++;
    
            // Handle nulvragen separately
            if (themeId == 11 && consolidatedData["nulvragen"]) {
                const groupData = consolidatedData["nulvragen"];
                const questions = groupData.questions;
                const questionIds = Object.keys(questions);
                let chartCounter = 0;
                let slide = null;
                const nullQuestionPages = {};
    
                for (let i = 0; i < questionIds.length; i++) {
                    const questionId = questionIds[i];
                    const questionData = questions[questionId];
    
                    if (chartCounter % 2 === 0) {
                        slide = pptx.addSlide();
                        slide.background = { fill: '#FBFCFE' };
    
                        addButtonsAndLegend(
                            pptx,
                            slide,
                            mergeDetails,
                            pixelToInch,
                            pptx.slides.indexOf(titleSlide),
                            'Start vragen',
                            '',
                            ''
                        );
                    }
    
                    const positionIndex = chartCounter % 2;
                    const xPos = firstChartX;
                    const yPos = firstChartY + ((chartHeight + margin) * positionIndex);
    
                    slide.addImage({
                        data: questionData.image,
                        x: xPos,
                        y: yPos,
                        w: wideChartWidth,
                        h: chartHeight,
                    });
    
                    nullQuestionPages[questionId] = pptx.slides.indexOf(slide);
                    chartCounter++;
                }
                continue;
            }
    
            for (const [subthemeId, subtheme] of relevantSubthemes) {
                const subthemeName = subtheme.name;
                if (presentationType === 'selection') {
                    if (combinedDataPerSubtheme[subthemeId] && combinedDataPerSubtheme[subthemeId].questions.length) {
                        const combinedGroupData = combinedDataPerSubtheme[subthemeId];
    
                        let slide = pptx.addSlide();
                        slide.background = { fill: '#FBFCFE' };
    
                        const usedMerges = await addChartsAndText(
                            pptx,
                            slide,
                            combinedGroupData,
                            firstChartX,
                            firstChartY,
                            chartWidth,
                            chartHeight,
                            wideChartWidth,
                            margin,
                            pixelToInch,
                            pptx.slides.indexOf(titleSlide),
                            mergeDetails,
                            themeName,
                            subthemeName,
                            '' // Empty questionGroupname
                        );
    
                        if (Object.keys(usedMerges).length > 0) {
                            addButtonsAndLegend(
                                pptx,
                                slide,
                                mergeDetails,
                                pixelToInch,
                                pptx.slides.indexOf(titleSlide),
                                themeName,
                                subthemeName,
                                ''
                            );
    
                            // Store the page number for the subtheme
                            subthemePages[themeId][subthemeId] = pptx.slides.indexOf(slide);
    
                            // Add button to theme slide
                            const themeSlideInfo = themeSlides[themeId];
                            const { slide: themeSlide, counter, xPos, initialY, yIncrement } = themeSlideInfo;
    
                            const yPos = initialY + counter * yIncrement;
                            const buttonHeight = pixelsToInches(84);
    
                            themeSlide.addImage({
                                path: '/images/powerpoint_presentation/small_button.png',
                                x: xPos,
                                y: yPos,
                                w: pixelsToInches(257),
                                h: buttonHeight,
                            });
    
                            themeSlide.addText(
                                subtheme.name,
                                {
                                    x: xPos,
                                    y: yPos,
                                    w: pixelsToInches(257),
                                    h: buttonHeight,
                                    fontSize: 18,
                                    color: '000000',
                                    bold: true,
                                    align: 'center',
                                    valign: 'middle',
                                    underline: false,
                                }
                            );
                            const subthemeSlideIndex = pptx.slides.indexOf(slide) + 1;
    
                            themeSlide.addShape(pptx.ShapeType.rect, {
                                x: xPos,
                                y: yPos,
                                w: pixelsToInches(257),
                                h: buttonHeight,
                                fill: { color: 'FFFFFF', transparency: 100 },
                                line: { color: 'FFFFFF', transparency: 100 },
                                hyperlink: {
                                    slide: subthemeSlideIndex,
                                },
                            });
    
                            // Increment the subtheme counter
                            themeSlideInfo.counter++;
    
                            processedSlides++;
    
                            const textProgress = 50 + (processedSlides / totalSlides) * 50;
                            setProgress(textProgress.toFixed(2));
                        } else {
                            pptx.slides.pop(); // Remove slide if no data was added
                        }
                    }
                } else {
                    let subthemeAddedToThemeSlide = false;
                    for (const qgId in consolidatedData) {
                        const groupData = consolidatedData[qgId];
                        if (qgId === "nulvragen") {
                            continue;
                        }
                        let questionGroupname = questiongroups[qgId].name;
    
                        if (groupData.subtheme_id == subthemeId) {
                            let slide = pptx.addSlide();
                            slide.background = { fill: '#FBFCFE' };
    
                            const usedMerges = await addChartsAndText(
                                pptx,
                                slide,
                                groupData,
                                firstChartX,
                                firstChartY,
                                chartWidth,
                                chartHeight,
                                wideChartWidth,
                                margin,
                                pixelToInch,
                                pptx.slides.indexOf(titleSlide),
                                mergeDetails,
                                themeName,
                                subthemeName,
                                questionGroupname
                            );
    
                            if (Object.keys(usedMerges).length > 0) {
                                addButtonsAndLegend(
                                    pptx,
                                    slide,
                                    mergeDetails,
                                    pixelToInch,
                                    pptx.slides.indexOf(titleSlide),
                                    themeName,
                                    subthemeName,
                                    questionGroupname
                                );
    
                                if (!subthemeAddedToThemeSlide) {
                                    // Store the page number for the subtheme
                                    subthemePages[themeId][subthemeId] = pptx.slides.indexOf(slide);
        
                                    // Add button to theme slide
                                    const themeSlideInfo = themeSlides[themeId];
                                    const { slide: themeSlide, counter, xPos, initialY, yIncrement } = themeSlideInfo;
        
                                    const yPos = initialY + counter * yIncrement;
                                    const buttonHeight = pixelsToInches(84);
        
                                    themeSlide.addImage({
                                        path: '/images/powerpoint_presentation/small_button.png',
                                        x: xPos,
                                        y: yPos,
                                        w: pixelsToInches(257),
                                        h: buttonHeight,
                                    });
        
                                    themeSlide.addText(
                                        subtheme.name,
                                        {
                                            x: xPos,
                                            y: yPos,
                                            w: pixelsToInches(257),
                                            h: buttonHeight,
                                            fontSize: 18,
                                            color: '000000',
                                            bold: true,
                                            align: 'center',
                                            valign: 'middle',
                                            underline: false,
                                        }
                                    );
        
                                    themeSlide.addShape(pptx.ShapeType.rect, {
                                        x: xPos,
                                        y: yPos,
                                        w: pixelsToInches(257),
                                        h: buttonHeight,
                                        fill: { color: 'FFFFFF', transparency: 100 },
                                        line: { color: 'FFFFFF', transparency: 100 },
                                        hyperlink: {
                                            slide: pptx.slides.indexOf(slide) + 1,
                                        },
                                    });


                                    // Increment the subtheme counter
                                    themeSlideInfo.counter++;

                                    subthemeAddedToThemeSlide = true;

                                }
        
    
                                processedSlides++;
    
                                const textProgress = 50 + (processedSlides / totalSlides) * 50;
                                setProgress(textProgress.toFixed(2));
                            } else {
                                pptx.slides.pop(); // Remove slide if no data was added
                            }
                        }
                    }
                }
            }
        }
    
        setCurrentComment(`Bezig met het maken van conclusies...`);
        setStatusMessage(`Bezig met het maken van conclusies...`);
    
        await sendCommentsForAnalysis(pptx);
    
        addChapterLinksToslide6(pptx, chapters);
    
        setCurrentComment(`Het rapport is voltooid staat in uw downloads...`);
        setStatusMessage(`Het rapport is voltooid staat in uw downloads...`);
    
        await pptx.writeFile({ fileName: 'SurveyResults.pptx' });
    };
                    




    useEffect(() => {
        //fetch(config.apiUrlRest + '/api/survey/873/merged-folders/')
//        fetch(config.apiUrlRest + '/api/survey/1355/merged-folders/')
        fetch(config.apiUrlRest + '/api/survey/1389/merged-folders/')
            .then(response => response.json())
            .then(data => {
                setData(data);
            })
            .catch(error => {
                console.error("There was an error fetching the survey data!", error);
            });
    }, []);


    const handleCheckboxChange = (type, id = null) => {
        setSelectedItems(prevState => {
            // Count the number of selected checkboxes in both 'folderMerges' and 'groep'
            const countFolderMergesChecked = Object.values(prevState.folderMerges).filter(value => value === true).length;
            const countGroepChecked = ['survey', 'company', 'sector', 'project']
                .filter(key => prevState[key] === true).length;
            const countChecked = countFolderMergesChecked + countGroepChecked;
    
            // If already 3 are checked and attempting to check a new one, block the change
            if (countChecked >= 5 && (type === 'folderMerges' ? !prevState.folderMerges[id] : !prevState[type])) {
                return { ...prevState, maxLimitReached: true };
            }
    
            // Update the checkbox state if the limit has not been reached
            return {
                ...prevState,
                maxLimitReached: countChecked >= 5,
                ...(type === 'folderMerges' ? {
                    folderMerges: {
                        ...prevState.folderMerges,
                        [id]: !prevState.folderMerges[id]
                    }
                } : {
                    [type]: !prevState[type]
                })
            };
        });
    };
    
    
    
    

    const isSubmitDisabled = () => {
        const { company, sector, project, folderMerges } = selectedItems;
        const isAnyFolderMergeSelected = Object.values(folderMerges).some(value => value);
        return !(company || sector || project || isAnyFolderMergeSelected);
    };

    const handleSubmit = (newPresentationType, input_ids) => {
        console.log('Presentation Type:', newPresentationType);
        presentationType = newPresentationType;
    
        // Verwerk de input direct
        const ids = input_ids
            .split(/[\s,]+/) // Splits op spaties, nieuwe regels of komma's
            .map((id) => id.trim()) // Verwijder overtollige spaties
            .filter((id) => /^\d+$/.test(id)) // Houd alleen geldige nummers
            .map(Number); // Converteer naar nummers
    
        console.log('Processed Chart IDs:', ids);
        chartIds = ids;
    
        setShowProgressBar(true);
        setIsProcessing(true);
        setStatusMessage('Ophalen onderzoek data...');
    
        // get presentation_type


        // Fetch survey results data
        //fetch(config.apiUrlRest + '/api/survey/873/results/')
        //fetch(config.apiUrlRest + '/api/survey/1355/results/')
        fetch(config.apiUrlRest + '/api/survey/1389/results/')
            .then(response => response.json())
            .then(resultsData => {
                setStatusMessage('Verwerken onderzoek data...');
                console.log("Fetched resultsData: ", resultsData);
                generateCharts(resultsData);
            })
            .catch(error => {
                console.error('Error fetching survey results:', error);
                setStatusMessage('Er is een fout tijdens het ophalen van de onderzoek data.');
                setIsProcessing(false);
            });
    };

    
    const aggregateData = (filteredData, scale) => {
        const data = {};
    
        // Initialize data structure based on scale values
        scale.scaleValues.forEach(value => {
            data[value] = 0;
        });
    
        // Aggregate responses, but only count valid answers
        filteredData.forEach(response => {
            const answer = response.answer;
            if (data.hasOwnProperty(answer)) {
                data[answer]++;
            }
            // Ignore answers that don't exist in the scale
        });
    
        return data;
    };

    const generateChartImage = (title, data, isQuestionGroup, mergeDetails, isWideChart) => {
        const chartPixelWidth = isWideChart ? 1536 : 756; // Wide charts are double the width
        const chartPixelHeight = 388;
    
        const canvas = document.createElement('canvas');
        canvas.width = chartPixelWidth;
        canvas.height = chartPixelHeight;
        const ctx = canvas.getContext('2d');
    
        const chartWidth = canvas.width;
        const chartHeight = canvas.height;
    
        // Ensure Inter font is loaded and used
        ctx.font = '18px Inter, sans-serif';
    
        // Apply rounded corners
        const radius = 8; // 8px corner radius
        ctx.beginPath();
        ctx.moveTo(radius, 0);
        ctx.lineTo(chartWidth - radius, 0);
        ctx.quadraticCurveTo(chartWidth, 0, chartWidth, radius);
        ctx.lineTo(chartWidth, chartHeight - radius);
        ctx.quadraticCurveTo(chartWidth, chartHeight, chartWidth - radius, chartHeight);
        ctx.lineTo(radius, chartHeight);
        ctx.quadraticCurveTo(0, chartHeight, 0, chartHeight - radius);
        ctx.lineTo(0, radius);
        ctx.quadraticCurveTo(0, 0, radius, 0);
        ctx.closePath();
        ctx.fillStyle = isQuestionGroup ? 'lightblue' : 'lightyellow';
        ctx.fill();
    
        // Apply clipping with the same path for rounded corners
        //ctx.clip();
    
        // Now draw the border with rounded corners
        ctx.strokeStyle = '#DDE1E6'; // Light gray border color
        ctx.lineWidth = 1;
        ctx.stroke();
    
        // Set the background color
        //ctx.fillStyle = isQuestionGroup ? 'lightblue' : 'lightyellow';
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    
        if (presentationType == 'draft' && data.question_id > 0) {
            // Data met het ID
            const chartId = data.question_id;
    
            // Stel de tekststijl in
            ctx.fillStyle = '#eee'; // Kleur van de tekst
            ctx.font = '350px Inter, sans-serif';
            ctx.textAlign = 'center'; // Horizontale uitlijning
            ctx.textBaseline = 'middle'; // Verticale uitlijning
    
            // Bereken de positie (midden van het canvas)
            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;
    
            // Teken de tekst in het midden
            ctx.fillText(chartId, centerX, centerY);
        }
    
        // Apply border and border-radius
        ctx.strokeStyle = '#DDE1E6';
        ctx.lineWidth = 1;
        ctx.strokeRect(0.5, 0.5, canvas.width - 1, canvas.height - 1); // Adjust for pixel perfect border
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = '#fff';
        ctx.lineJoin = 'round';
        ctx.lineWidth = 8;
        ctx.strokeRect(4, 4, canvas.width - 8, canvas.height - 8);
        ctx.globalCompositeOperation = 'source-over';
    
        // Draw the title with wrapping if necessary
        ctx.fillStyle = '#000000';
        ctx.font = '600 18px Inter, sans-serif'; // Ensure Inter font is used for title
        ctx.textAlign = 'center';
    
        const maxTextWidth = canvas.width - 40; // Allow some padding on both sides
        const words = title.split(' ');
        let line = '';
        const lines = [];
        const lineHeight = 24; // Height of each line of text
    
        words.forEach((word) => {
            const testLine = line + word + ' ';
            const testWidth = ctx.measureText(testLine).width;
            if (testWidth > maxTextWidth && line !== '') {
                lines.push(line);
                line = word + ' ';
            } else {
                line = testLine;
            }
        });
        lines.push(line); // Add the last line
    
        const initialY = 30;
        let y = initialY;
    
        lines.forEach((line) => {
            ctx.fillText(line.trim(), canvas.width / 2, y);
            y += lineHeight;
        });
    
        const chartStartY = y + 10;
    
        const scale = data.scale;
        const merges = Object.keys(data.data);
        const maxBarHeight = 240; // Adjusted to fit within the canvas height
        const innerChartWidth = chartWidth - 90;
        const chartStartX = 60;
        const yAxisPosition = chartHeight - 50;
    
        // Draw the x-axis line
        ctx.strokeStyle = 'lightgray';
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(chartStartX, yAxisPosition);
        ctx.lineTo(chartStartX + innerChartWidth, yAxisPosition);
        ctx.stroke();
    
        // Draw horizontal grid lines
        [20, 40, 60, 80, 100].forEach((percent) => {
            const y = yAxisPosition - (percent * maxBarHeight / 100);
            ctx.strokeStyle = 'lightgray';
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(chartStartX, y);
            ctx.lineTo(chartStartX + innerChartWidth, y);
            ctx.stroke();
    
            ctx.fillStyle = '#000000';
            ctx.font = '600 14px Inter, sans-serif';
            ctx.textAlign = 'right';
            ctx.fillText(`${percent}%`, chartStartX - 10, y + 4);
        });
    
        // Sort the scale values numerically
        const sortedScaleValues = Object.keys(scale).map(Number).sort((a, b) => a - b);
    
        const scaleUnits = sortedScaleValues.length;
        const unitWidth = innerChartWidth / scaleUnits;
        const groupSpacing = Math.max(20, unitWidth - (16 * merges.length + (3 * (merges.length - 1))));
        const availableWidthForBars = unitWidth - groupSpacing;
        const adjustedBarWidth = Math.min(40, Math.max(16, availableWidthForBars / merges.length));
    
        // Draw bars and labels
        sortedScaleValues.forEach((scaleValue, index) => {
            const unitStartX = chartStartX + index * unitWidth;
    
            merges.forEach((merge, mergeIndex) => {
                const mergeColor = mergeDetails[merge].color || `hsl(${mergeIndex * 360 / merges.length}, 70%, 50%)`;
                const totalResponses = Object.values(data.data[merge]).reduce((acc, count) => acc + count, 0);
                const responseCount = data.data[merge][scaleValue] || 0;
    
                if (!scale.hasOwnProperty(scaleValue)) {
                    return;
                }
    
                const percentage = totalResponses ? (responseCount / totalResponses) * 100 : 0;
                const barHeight = (percentage / 100) * maxBarHeight;
    
                const barX = unitStartX + (mergeIndex * (adjustedBarWidth + 3)) + groupSpacing / 2;
    
                ctx.fillStyle = mergeColor;
                ctx.fillRect(barX, yAxisPosition - barHeight, adjustedBarWidth, barHeight);
    
                if (barHeight > 0) {
                    ctx.fillStyle = '#000000';
                    ctx.font = '12px Inter, sans-serif'; // Numbers above bars in 12px Inter font
                    ctx.textAlign = 'center';
                    ctx.fillText(`${Math.round(percentage)}`, barX + adjustedBarWidth / 2, yAxisPosition - barHeight - 10);
                }
            });
    
            // Draw a square representing the area for the text block
            const squareWidth = unitWidth - 10; // Subtract some padding to fit within the unit
            const squareHeight = 36; // Fixed height for 2 lines of text
            const squareX = unitStartX + unitWidth / 2 - squareWidth / 2;
            const squareY = yAxisPosition + 10; // Position higher to fit within the chart
    
            // Calculate the text positioning inside the square
            ctx.fillStyle = '#000000';
            ctx.font = '600 14px Inter, sans-serif';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
    
            const label = scale[scaleValue] || '';
            const labelWords = label.split(' ');
    
            let currentLine = '';
            const labelLines = [];
    
            // Split the label into lines based on the square's width
            labelWords.forEach((word) => {
                const testLine = currentLine + word + ' ';
                const testWidth = ctx.measureText(testLine).width;
                if (testWidth > squareWidth && currentLine !== '') {
                    labelLines.push(currentLine.trim());
                    currentLine = word + ' ';
                } else {
                    currentLine = testLine;
                }
            });
            labelLines.push(currentLine.trim()); // Add the last line
    
            // Ensure text is centered within the square
            const totalLabelHeight = labelLines.length * 18; // Calculate total height of the label
            let labelYPosition = squareY + (squareHeight - totalLabelHeight) / 2 + 9; // Adjust vertical centering
    
            // Draw each line of text
            labelLines.forEach((line) => {
                const textWidth = ctx.measureText(line).width;
                const textX = unitStartX + unitWidth / 2; // Center the text horizontally within the square
                ctx.fillText(line, textX, labelYPosition);
                labelYPosition += 18; // Move to the next line
            });
        });
    
        return canvas.toDataURL();
    };
        
        
        
    
                  
    const constructSelectedMerges = () => {
        let selectedMerges = [];
    
        // Add survey if selected
        if (selectedItems.survey) {
            selectedMerges.push('survey');
        }
    
        // Add selected folder merges
        Object.keys(selectedItems.folderMerges).forEach(mergeId => {
            if (selectedItems.folderMerges[mergeId]) {
                selectedMerges.push(`foldermerge_${mergeId}`);
            }
        });
    
        return selectedMerges;
    };

    const generateCharts = async (results) => {
        try {
            let total = 0;
            let processed = 0;
    
            let selectedMerges = constructSelectedMerges();
    
            let uniqueQuestionGroups = new Set();
            let uniqueQuestions = new Set();
    
            const mergeDetails = {};
            data.folder_merges.forEach(merge => {
                const mergeId = `foldermerge_${merge.id}`;
    
                // Voeg alleen toe aan mergeDetails als het geselecteerd is
                if (selectedMerges.includes(mergeId)) {
                    mergeDetails[mergeId] = {
                        name: merge.name,
                        color: merge.chart_color
                    };
                }
            });

            // Count unique question groups and questions
            selectedMerges.forEach(merge => {
                if (results.results[merge]) {
                    const mergeData = results.results[merge];
    
                    // Count unique question groups
                    Object.keys(mergeData.questiongroups).forEach(qgId => {
                        if (!uniqueQuestionGroups.has(qgId)) {
                            uniqueQuestionGroups.add(qgId);
                        }
    
                        // Count unique questions within the question group
                        Object.keys(mergeData.questiongroups[qgId].questions).forEach(questionId => {
                            if (presentationType === 'selection') {
                                // Check if questionId is in chartIds array
                                if (chartIds.includes(parseInt(questionId))) {
                                    if (!uniqueQuestions.has(questionId)) {
                                        uniqueQuestions.add(questionId);
                                        total++;
                                    }
                                }
                            } else {
                                // Default behavior for other presentation types
                                if (!uniqueQuestions.has(questionId)) {
                                    uniqueQuestions.add(questionId);
                                    total++;
                                }
                            }
                        });
                    });
                }
            });
    
            const themes = results.themes;
            const subthemes = results.subthemes;
            const questiongroups = results.questiongroups;
            const questions = results.questions;
            const scales = results.scales;
    
            let consolidatedData = {};
    

            // Loop through themes
            for (const themeId in themes) {
                const themeName = themes[themeId];
                setStatusMessage(`Thema: ${themeName}`);
    
                // Loop through subthemes under this theme
                const relevantSubthemes = Object.entries(subthemes).filter(([subthemeId, subtheme]) => subtheme.theme_id === parseInt(themeId));
                for (const [subthemeId, subtheme] of relevantSubthemes) {
    
                    // Loop through question groups used in this subtheme
                    const relevantQuestionGroups = Array.from(uniqueQuestionGroups).filter(qgId => questiongroups[qgId].subtheme_id === parseInt(subthemeId));
                    for (const qgId of relevantQuestionGroups) {
                        const questionGroup = questiongroups[qgId];
    
                        if (!consolidatedData[qgId]) {
                            consolidatedData[qgId] = {
                                name: questionGroup.name,
                                scale: scales[questionGroup.scale_id],
                                questions: {},
                                subtheme_id: subthemeId
                            };
                        }
    
                        for (const merge of selectedMerges) {
                            if (results.results[merge] && results.results[merge].questiongroups[qgId]) {
                                const mergeData = results.results[merge].questiongroups[qgId];
    
                                const relevantQuestions = Array.from(uniqueQuestions).filter(qId => questions[qId].qg === parseInt(qgId));
                                for (const questionId of relevantQuestions) {
                                    const question = questions[questionId];
    
                                    if (!mergeData.questions[questionId]) {
                                        continue; 
                                    }
    
                                    if (!consolidatedData[qgId].questions[questionId]) {
                                        consolidatedData[qgId].questions[questionId] = {
                                            question_id: questionId,
                                            name: question.name,
                                            scale: scales[question.scale],
                                            data: {}
                                        };
                                    }
    
                                    consolidatedData[qgId].questions[questionId].data[merge] = mergeData.questions[questionId].results;
                                }
                            }
                        }
    
                        // Generate the image for each question
                        for (const questionId in consolidatedData[qgId].questions) {
                            const questionData = consolidatedData[qgId].questions[questionId];
    
                            // Determine if the chart for the question should be wide
                            const numberOfScales = Object.keys(questionData.scale).length;
                            const numberOfMerges = selectedMerges.length; // Use the number of selected merges
    
                            const questionIsWideChart = (
                                (numberOfScales * numberOfMerges) > 25 ||
                                numberOfScales > 6
                            );
    
                            // Output debug info
                            //console.log(`Question ID: ${questionId}, isWideChart: ${questionIsWideChart}, numberOfScales: ${numberOfScales}, numberOfMerges: ${numberOfMerges}`);
    
                            // Generate the image with the wide parameter
                            const questionImage = generateChartImage(questionData.name, questionData, false, mergeDetails, questionIsWideChart);
                            consolidatedData[qgId].questions[questionId].image = questionImage;
                            consolidatedData[qgId].questions[questionId].isWide = questionIsWideChart;
                            
                            setCurrentChart({
                                image: questionImage,
                                data: questionData,
                                isWideChart: questionIsWideChart
                            });
    
                            processed++;
                            setProgress(((processed / total) * 50).toFixed(2));

                            await new Promise((resolve) => setTimeout(resolve, 0));
                        }
                    }
                }
            }

            consolidatedData["nulvragen"] = {
                name: "nulvragen",
                questions: {}
            };

            // consolideer data voor nulvragen.
            for (const merge of selectedMerges) {
                if (results.results[merge] && results.results[merge].generalquestions) {
                    const generalQuestionsData = results.results[merge].generalquestions;

                    for (const generalQuestionId in generalQuestionsData) {
                        const generalQuestion = generalQuestionsData[generalQuestionId];
            
                        // Initializeer de vraag in consolidatedData als die nog niet bestaat
                        if (!consolidatedData["nulvragen"].questions[generalQuestionId]) {
                            consolidatedData["nulvragen"].questions[generalQuestionId] = {
                                name: generalQuestionId, // Of gebruik een mapping als je een meer beschrijvende naam hebt
                                scale: scales[generalQuestion.scale], // Zorg dat 'scales' de juiste schaalinformatie bevat
                                data: {}
                            };
                        }
            
                        // Sla de resultaten per merge op
                        consolidatedData["nulvragen"].questions[generalQuestionId].data[merge] = generalQuestion.results;
                    }
                }
            }

            // Maak een object voor de vertalingen van de nulvragen.
            const nameMapping = {
                gender: "Geslacht",
                age: "Leeftijd",
                education: "Opleiding",
                nationality: "Nationaliteit",
                assignment: "Vast/tijdelijk contract",
                years_employed: "Aantal jaar werkzaam",
                years_job: "Aantal jaar in dienst bij huidige werkgever",
                workhours: "Aantal uur werkzaam per week"
            };
            const getDisplayName = (name) => {
                return nameMapping[name] || name;
            };

            // Genereer afbeeldingen voor de general questions
            for (const questionId in consolidatedData["nulvragen"].questions) {
                const questionData = consolidatedData["nulvragen"].questions[questionId];

                // Bepaal of de grafiek breed moet zijn
                const numberOfScales = Object.keys(questionData.scale).length;
                const numberOfMerges = selectedMerges.length;

                const questionIsWideChart = true;


                // Genereer de afbeelding met de juiste parameters
                const questionImage = generateChartImage(
                    getDisplayName(questionData.name),
                    questionData,
                    false,
                    mergeDetails,
                    questionIsWideChart
                );

                consolidatedData["nulvragen"].questions[questionId].image = questionImage;
                consolidatedData["nulvragen"].questions[questionId].isWide = questionIsWideChart;

                setCurrentChart({
                    image: questionImage,
                    data: questionData,
                    isWideChart: questionIsWideChart
                });

                await new Promise((resolve) => setTimeout(resolve, 0));
            }

            // Generate and download the PowerPoint
            await generatePowerPointWithAIComments(consolidatedData, themes, subthemes, questiongroups, mergeDetails);
    
            setProgress(100);
            setIsProcessing(false);
        } catch (error) {
            console.error('Error during chart generation:', error);
            setStatusMessage('An error occurred while processing the survey.');
            setIsProcessing(false);
        }
    };        
    
            
    if (!data) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <ComponentLabel label='survey/tab8/Tab8' />
            {!showProgressBar ? (
                <Tab8FolderSelection
                    data={data}
                    selectedItems={selectedItems}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSubmit={handleSubmit}
                    isSubmitDisabled={isSubmitDisabled}
                />
            ) : (
                <Tab8GeneratePresentation
                    progress={progress}
                    statusMessage={statusMessage}
                    currentChart={currentChart}
                    currentComment={currentComment}
                />
            )}
        </div>
    );
};

export default Tab8;
