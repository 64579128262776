/**
* The `UpdateComponentOption` React component provides an interface for users to edit and submit JavaScript and CSS code related to a specified label, fetching existing code from an API and allowing updates through a text area and code editors. It can be used in applications where dynamic code editing and submission are required, such as in a code generation or component customization tool.
*/
import React, { useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import config from '../../config';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/theme-monokai';
import CircularProgress from '@mui/material/CircularProgress';

const UpdateComponentOption = ({ label }) => {
  const [inputText, setInputText] = useState('');
  const [jsCode, setJsCode] = useState('');
  const [cssCode, setCssCode] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State to track loading

  const handleInputChange = (event) => setInputText(event.target.value);
  const handleJsChange = (newCode) => setJsCode(newCode);
  const handleCssChange = (newCode) => setCssCode(newCode);

  useEffect(() => {
    if (label) fetchCode(label);
  }, [label]);

  const fetchCode = async (label) => {
    try {
      const response = await fetch(`${config.apiUrlCodegen}/api/get_react_component_code/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ label }),
      });
      if (response.ok) {
        const data = await response.json();
        setJsCode(data.jsCode);
        setCssCode(data.cssCode);
      }
    } catch (error) {
      console.error('Error fetching code:', error);
    }
  };

  const handleSubmit = async (type) => {
    let bodyData = { label };
    if (type === 'Prompt') {
      bodyData.content = inputText;
    } else if (type === 'JavaScript') {
      bodyData.jsCode = jsCode;
    } else if (type === 'CSS') {
      bodyData.cssCode = cssCode;
    }

    setIsLoading(true); // Start loading spinner
    try {
      const response = await fetch(`${config.apiUrlCodegen}/api/${type === 'Prompt' ? 'update_react_component' : 'save_react_component_code'}/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyData),
      });
      if (!response.ok) throw new Error(`${type} submission failed`);
      console.log(`${type} submitted successfully`);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Stop loading spinner
    }
  };

  return (
    <div className="popup-content">
      <textarea value={inputText} onChange={handleInputChange} className="popup-textarea" />
      <button onClick={() => handleSubmit('Prompt')} className="popup-button" disabled={isLoading}>
        Vraag aan AI om bij te werken
        {isLoading && (
          <CircularProgress
            size={16} // Smaller size
            style={{ marginLeft: 10, color: 'white' }} // White color
          />
        )}
      </button>
      <div className="editor-container">
        <div className="editor-section">
          <AceEditor
            mode="javascript"
            theme="monokai"
            name="js_editor"
            value={jsCode}
            onChange={handleJsChange}
            editorProps={{ $blockScrolling: true }}
            style={{ width: '100%', height: '400px' }}
          />
          <button onClick={() => handleSubmit('JavaScript')} className="popup-button" disabled={isLoading}>
            Sla JavaScript op
          </button>
        </div>
        <div className="editor-section">
          <AceEditor
            mode="css"
            theme="monokai"
            name="css_editor"
            value={cssCode}
            onChange={handleCssChange}
            editorProps={{ $blockScrolling: true }}
            style={{ width: '100%', height: '400px' }}
          />
          <button onClick={() => handleSubmit('CSS')} className="popup-button" disabled={isLoading}>
            Sla CSS op
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateComponentOption;
