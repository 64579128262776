// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-tab4tabs {
  font-family: Arial, sans-serif;
}

.survey-tab4tabs__tabs {
  display: flex;
  margin-bottom: 10px;
}

.survey-tab4tabs__tab {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  margin-right: 5px;
}

.survey-tab4tabs__tab.active {
  background-color: #007bff;
  color: white;
}

.survey-tab4tabs__content {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab4/Tab4Tabs.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,sBAAsB;EACtB,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".survey-tab4tabs {\n  font-family: Arial, sans-serif;\n}\n\n.survey-tab4tabs__tabs {\n  display: flex;\n  margin-bottom: 10px;\n}\n\n.survey-tab4tabs__tab {\n  padding: 10px 20px;\n  cursor: pointer;\n  border: 1px solid #ccc;\n  background-color: #f1f1f1;\n  margin-right: 5px;\n}\n\n.survey-tab4tabs__tab.active {\n  background-color: #007bff;\n  color: white;\n}\n\n.survey-tab4tabs__content {\n  border: 1px solid #ccc;\n  padding: 10px;\n  background-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
