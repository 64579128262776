import React, { useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './Tab4Tabs.css';
import Tab4Overview from './Tab4Overview.js'
import Tab4AddRespondents from './Tab4AddRespondents.js'

const Tab4Tabs = () => {
  const [activeTab, setActiveTab] = useState('overzicht');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const dummyData = {
    overzicht:  <Tab4Overview />,
    nieuweRespondenten: <Tab4AddRespondents />
  };

  return (
    <div className='survey-tab4tabs'>
      <ComponentLabel label='survey/Tab4/Tab4Tabs' />
      <div className='survey-tab4tabs__tabs'>
        <button className={`survey-tab4tabs__tab ${activeTab === 'overzicht' ? 'active' : ''}`} onClick={() => handleTabChange('overzicht')}>Overzicht</button>
        <button className={`survey-tab4tabs__tab ${activeTab === 'nieuweRespondenten' ? 'active' : ''}`} onClick={() => handleTabChange('nieuweRespondenten')}>Nieuwe respondenten</button>
      </div>
      <div className='survey-tab4tabs__content'>
        {activeTab === 'overzicht' ? dummyData.overzicht : dummyData.nieuweRespondenten}
      </div>
    </div>
  );
};

export default Tab4Tabs;