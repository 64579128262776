/**
* The `AddComponentOption` React component allows users to add existing or new React components by selecting from a list of modules and components or by entering new module and component names, respectively. It can be used in a modal or form interface where users need to manage React components dynamically.
*/
import React, { useState, useEffect } from 'react';
import config from '../../config';
import CircularProgress from '@mui/material/CircularProgress';

const AddComponentOption = ({ label, closeModal }) => {
  const [modules, setModules] = useState([]);
  const [functions, setFunctions] = useState([]);
  const [selectedModule, setSelectedModule] = useState('');
  const [selectedComponent, setSelectedComponent] = useState('');
  const [newModuleName, setNewModuleName] = useState('');
  const [newComponentName, setNewComponentName] = useState('');
  const [newComponentPrompt, setNewComponentPrompt] = useState('');
  const [isLoadingExisting, setIsLoadingExisting] = useState(false); // Spinner for existing component
  const [isLoadingNew, setIsLoadingNew] = useState(false); // Spinner for new component

  useEffect(() => {
    fetchModules();
  }, []);

  const fetchModules = async () => {
    try {
      const response = await fetch(`${config.apiUrlCodegen}/api/get_react_modules/`);
      if (response.ok) {
        const data = await response.json();
        setModules(data.modules);
      }
    } catch (error) {
      console.error('Error fetching modules:', error);
    }
  };

  const fetchFunctions = async (moduleName) => {
    try {
      const response = await fetch(`${config.apiUrlCodegen}/api/get_react_components?module=${moduleName}`);
      if (response.ok) {
        const data = await response.json();
        setFunctions(data.components);
      }
    } catch (error) {
      console.error('Error fetching functions:', error);
    }
  };

  const handleModuleChange = (e) => {
    setSelectedModule(e.target.value);
    setFunctions([]);
    if (e.target.value) fetchFunctions(e.target.value);
  };

  const handleExistingSubmit = async () => {
    if (!selectedModule || !selectedComponent) {
      alert('Selecteer een module en een component.');
      return;
    }

    setIsLoadingExisting(true); // Start spinner for existing component
    const data = { module: selectedModule, component: selectedComponent, label };
    try {
      const response = await fetch(`${config.apiUrlCodegen}/api/add_existing_react_component/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      if (response.ok) alert('Bestaand component toegevoegd!');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoadingExisting(false); // Stop spinner
    }
  };

  const handleNewSubmit = async () => {
    if (!newModuleName || !newComponentName) {
      alert('Geef een module- en componentnaam op.');
      return;
    }

    setIsLoadingNew(true); // Start spinner for new component
    const data = { module: newModuleName, component: newComponentName, prompt: newComponentPrompt, label };
    try {
      const response = await fetch(`${config.apiUrlCodegen}/api/create_react_component/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      if (response.ok) alert('Nieuw component toegevoegd!');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoadingNew(false); // Stop spinner
    }
  };

  return (
    <div className="form-sections">
      {/* Existing Component Section */}
      <div className="existing-component-section">
        <h3>Bestaand component</h3>
        <div className="existing-component">
          <label>Module:</label>
          <select
            value={selectedModule}
            onChange={handleModuleChange}
            className="full-width-select"
          >
            <option value="">Selecteer een module</option>
            {modules.map((module) => (
              <option key={module} value={module}>
                {module}
              </option>
            ))}
          </select>
          <label>Component:</label>
          <select
            value={selectedComponent}
            onChange={(e) => setSelectedComponent(e.target.value)}
            className="full-width-select"
            disabled={!selectedModule}
          >
            <option value="">Selecteer een component</option>
            {functions.map((func) => (
              <option key={func} value={func}>
                {func}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={handleExistingSubmit}
          className="popup-button"
          disabled={isLoadingExisting}
        >
          Voeg Bestaand toe
          {isLoadingExisting && (
            <CircularProgress size={16} style={{ marginLeft: 10, color: 'white' }} />
          )}
        </button>
      </div>

      {/* New Component Section */}
      <div className="new-component-section">
        <h3>Nieuw component</h3>
        <div className="new-component-form">
          <div className="input-row">
            <div className="input-column">
              <label>Module:</label>
              <input
                type="text"
                value={newModuleName}
                onChange={(e) => setNewModuleName(e.target.value)}
                className="half-width-input"
              />
            </div>
            <div className="input-column">
              <label>Naam:</label>
              <input
                type="text"
                value={newComponentName}
                onChange={(e) => setNewComponentName(e.target.value)}
                className="half-width-input"
              />
            </div>
          </div>
          <label>Prompt:</label>
          <textarea
            value={newComponentPrompt}
            onChange={(e) => setNewComponentPrompt(e.target.value)}
            className="full-width-textarea"
          />
        </div>
        <button
          onClick={handleNewSubmit}
          className="popup-button"
          disabled={isLoadingNew}
        >
          Maak Nieuw Component
          {isLoadingNew && (
            <CircularProgress size={16} style={{ marginLeft: 10, color: 'white' }} />
          )}
        </button>
      </div>
    </div>
  );
};

export default AddComponentOption;
