// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-tab7-upload {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.survey-tab7-upload-form {
  display: flex;
  flex-direction: column;
}

.survey-tab7-upload-input {
  margin-bottom: 10px;
}

.survey-tab7-upload-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 300px;
}

.survey-tab7-upload-file-name {
  margin-top: 10px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab7/Tab7Upload.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".survey-tab7-upload {\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n}\n\n.survey-tab7-upload-form {\n  display: flex;\n  flex-direction: column;\n}\n\n.survey-tab7-upload-input {\n  margin-bottom: 10px;\n}\n\n.survey-tab7-upload-button {\n  background-color: #007bff;\n  color: white;\n  border: none;\n  padding: 10px;\n  border-radius: 5px;\n  cursor: pointer;\n  width: 300px;\n}\n\n.survey-tab7-upload-file-name {\n  margin-top: 10px;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
