import React, { useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './Tab7Files.css';

const Tab7Files = () => {
  const [files, setFiles] = useState([
    { id: 1, name: 'file1.txt' },
    { id: 2, name: 'file2.txt' },
  ]);

  const handleDelete = (id) => {
    setFiles(files.filter(file => file.id !== id));
  };

  return (
    <div className='survey-tab7files'>
      <ComponentLabel label='survey/tab7/Tab7Files' />
      {files.length === 0 ? (
        <p>No files uploaded.</p>
      ) : (
        <ul className='survey-tab7files-list'>
          {files.map(file => (
            <li key={file.id} className='survey-tab7files-item'>
              {file.name}
              <span className='survey-tab7files-trash' onClick={() => handleDelete(file.id)}>&#x1F5D1;</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Tab7Files;