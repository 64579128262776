// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-tab5-merge-list {
  padding: 20px;
  margin-top:50px;
}

.survey-tab5-merge-list table {
  width: 100%;
  border-collapse: collapse;
}

.survey-tab5-merge-list th, .survey-tab5-merge-list td {
  border: 1px solid #ddd;
  padding: 8px;
}

.survey-tab5-merge-list th {
  background-color: #f2f2f2;
  text-align: left;
}

.survey-tab5-merge-list input[type='color'] {
  width: 50px;
  height: 30px;
  border: none;
  cursor: pointer;
}

.survey-tab5-merge-list button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.survey-tab5-merge-list button:hover {
  background-color: #d32f2f;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab5/Tab5MergeList.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".survey-tab5-merge-list {\n  padding: 20px;\n  margin-top:50px;\n}\n\n.survey-tab5-merge-list table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.survey-tab5-merge-list th, .survey-tab5-merge-list td {\n  border: 1px solid #ddd;\n  padding: 8px;\n}\n\n.survey-tab5-merge-list th {\n  background-color: #f2f2f2;\n  text-align: left;\n}\n\n.survey-tab5-merge-list input[type='color'] {\n  width: 50px;\n  height: 30px;\n  border: none;\n  cursor: pointer;\n}\n\n.survey-tab5-merge-list button {\n  background-color: #f44336;\n  color: white;\n  border: none;\n  padding: 5px 10px;\n  cursor: pointer;\n}\n\n.survey-tab5-merge-list button:hover {\n  background-color: #d32f2f;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
