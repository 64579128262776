// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-company-selector {
  position: absolute;
  top: 40px;
  left: 40px;
  margin: 0;
  padding: 0;
}

.common-company-selector-dropdown {
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}

.common-company-selector-dropdown:focus {
  border-color: #007bff;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/common/CompanySelector.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,sBAAsB;EACtB,wCAAwC;EACxC,6BAA6B;AAC/B;;AAEA;EACE,qBAAqB;EACrB,aAAa;AACf","sourcesContent":[".common-company-selector {\n  position: absolute;\n  top: 40px;\n  left: 40px;\n  margin: 0;\n  padding: 0;\n}\n\n.common-company-selector-dropdown {\n  padding: 10px;\n  margin: 5px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 16px;\n  background-color: #fff;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  transition: border-color 0.3s;\n}\n\n.common-company-selector-dropdown:focus {\n  border-color: #007bff;\n  outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
