import React, { useState } from 'react';
import ComponentLabel from '../codegen/ComponentLabel.js';
import CrudComponent from '../common/CrudComponent';
import './ListBranches.css';

const ListBranches = () => {
  const [branches, setBranches] = useState([
    { id: 1, name: 'Hoofdkantoor', location: 'Amsterdam' },
    { id: 2, name: 'Filiaal Zuid', location: 'Rotterdam' },
  ]);

  const handleSave = (updatedRecord, index) => {
    const updatedBranches = [...branches];
    updatedBranches[index] = updatedRecord;
    setBranches(updatedBranches);
  };

  const handleAdd = (newRecord) => {
    setBranches([...branches, { ...newRecord, id: branches.length + 1 }]);
  };

  const fieldConfigs = {
    name: { type: 'text' },
    location: { type: 'text' },
  };

  const headers = {
    name: 'Branchenaam',
    location: 'Locatie',
  };

  return (
    <div>
      <ComponentLabel label='configuration/ListBranches' />
      <CrudComponent
        data={branches}
        visibleFields={['name', 'location']}
        editableFields={['name', 'location']}
        headers={headers}
        fieldConfigs={fieldConfigs}
        onSave={handleSave}
        onAdd={handleAdd}
      />
    </div>
  );
};

export default ListBranches;
