// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-tab2-time-estimation {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.survey-tab2-time-estimation-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.survey-tab2-time-estimation-questions,
.survey-tab2-time-estimation-time {
  margin: 10px 0;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/survey/tab2/Tab2TimeEstimation.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,gCAAgC;EAChC,sBAAsB;EACtB,aAAa;EACb,yBAAyB;EACzB,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;;EAEE,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".survey-tab2-time-estimation {\n  position: absolute;\n  top: 200px;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  border: 1px solid #ccc;\n  padding: 20px;\n  background-color: #f9f9f9;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.survey-tab2-time-estimation-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.survey-tab2-time-estimation-questions,\n.survey-tab2-time-estimation-time {\n  margin: 10px 0;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
