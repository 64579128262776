import React, { useState } from 'react';
import ComponentLabel from '../codegen/ComponentLabel.js';
import './CompanySelector.css';

const CompanySelector = () => {
  const [selectedCompany, setSelectedCompany] = useState('');
  const companies = ['Bedrijf A', 'Bedrijf B', 'Bedrijf C']; // Dummy data

  const handleChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  return (
    <div className='common-company-selector'>
      <ComponentLabel label='common/CompanySelector' />
      <select value={selectedCompany} onChange={handleChange} className='common-company-selector-dropdown'>
        <option value=''>Selecteer een bedrijf</option>
        {companies.map((company, index) => (
          <option key={index} value={company}>{company}</option>
        ))}
      </select>
    </div>
  );
};

export default CompanySelector;