import React from 'react';
import ComponentLabel from '../codegen/ComponentLabel.js';
import ActiveSurvey from './ActiveSurvey';
import CreateSurvey from '../survey/CreateSurvey';
import './ActiveSurveys.css';

const ActiveSurveys = ({ surveys }) => {
  return (
    <div className='home-active-surveys'>
      <h1>Actieve onderzoeken</h1>
      <ComponentLabel label='home/ActiveSurveys' style={{top: '0px'}}/>
      <div className='home-active-surveys-grid'>
        {surveys.map(survey => (
          <ActiveSurvey
            key={survey.id}
            title={survey.name}
            totalRespondents={survey.total_respondents}
            finishedRespondents={survey.finished_respondents}
            companyName={survey.company.name}  // Pass the company name
          />
        ))}
        <CreateSurvey />
      </div>
    </div>
  );
};

export default ActiveSurveys;
