import React, { useState, useEffect } from 'react';  
import ComponentLabel from '../codegen/ComponentLabel.js';
import './ListCompanies.css';
import CrudComponent from '../common/CrudComponent';
import config from '../../config';

const ListCompanies = () => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    // Fetch companies from the Django REST API
    const fetchCompanies = async () => {
      try {
        const response = await fetch(`${config.apiUrlRest}/api/companies/`);
        const data = await response.json();
        
        // Map the API response to the format required by the component
        const formattedCompanies = data.map(company => ({
          id: company.tbl_company_id,
          name: company.tbl_company_name,
          branche: company.tbl_company_x_sector, // Assuming this represents the branch or industry
          visibleFrontend: company.tbl_company_active,
        }));
        
        setCompanies(formattedCompanies);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, []);

  const handleSave = (updatedRecord, index) => {
    const updatedCompanies = [...companies];
    updatedCompanies[index] = updatedRecord;
    setCompanies(updatedCompanies);
  };

  const handleAdd = (newRecord) => {
    setCompanies([...companies, { ...newRecord, id: companies.length + 1 }]);
  };

  const fieldConfigs = {
    name: { type: 'text' },
    branche: { type: 'text' },  // Adjust based on what input type you want
    visibleFrontend: { type: 'checkbox' },  // Assuming this is a boolean field
  };

  const headers = {
    name: 'Company Name',
    branche: 'Branche',
    visibleFrontend: 'Zichtbaar Frontend',
  };

  return (
    <div>
      <ComponentLabel label='configuration/ListCompanies' />
      
      <CrudComponent
        data={companies}
        visibleFields={['name', 'branche', 'visibleFrontend']}
        editableFields={['name', 'branche', 'visibleFrontend']}
        headers={headers}
        fieldConfigs={fieldConfigs}
        onSave={handleSave}
        onAdd={handleAdd}
      />
    </div>
  );
};

export default ListCompanies;
