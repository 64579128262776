import React from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';

const Tab6 = () => {
  return (
    <div>
      <ComponentLabel label="survey/tab6/Tab6" />
      
    </div>
  );
};

export default Tab6;