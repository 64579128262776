import React, { useState } from 'react';
import ComponentLabel from '../../codegen/ComponentLabel.js';
import './CompanyQuestionTags.css';

const CompanyQuestionTags = () => {
  const [tags, setTags] = useState([
    '(til)hulpmiddelen',
    'de directie',
    'directeur',
    'een agressieve klant of een gammel trapje',
    'externen',
    'incident',
    'leidinggevende',
    'ondernemingsraad',
    'onze organisatie',
    'oproepdienst',
    'productiemanager',
    'taken/machines',
    'tilhulpmiddelen of omkasting',
    'tussenmanager'
  ]);

  const [newTag, setNewTag] = useState('');

  const handleAddTag = () => {
    if (newTag) {
      setTags([...tags, newTag]);
      setNewTag('');
    }
  };

  return (
    <div className='survey-CompanyQuestionTags'>
      <ComponentLabel label='survey/tab_company/CompanyQuestionTags' />
      <div className='survey-CompanyQuestiontags-explanation'>
      De tags in de vragen worden vervangen door de ingevoerde waarde.
      </div>
      
      <div className='tags-container'>
        {tags.map((tag, index) => (
          <div key={index} className='tag-item'>
            <label>[{tag}]</label>
            <input type='text' value={tag} readOnly />
          </div>
        ))}
      </div>
      <div className='new-tag-input'>
        <input
          type='text'
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          placeholder='Maak een nieuwe tag'
        />
        <button onClick={handleAddTag}>Voeg toe</button>
      </div>
    </div>
  );
};

export default CompanyQuestionTags;
